import { noop } from '@angular-devkit/schematics';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDatepicker, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Caja, Camion, Destino, Orden } from 'src/app/app.modelsWebShop';
import { FincaPreferida } from "src/app/FincaPreferida";
import { AppService } from 'src/app/app.service';
import { InformationComponent } from '../../popus/information/information.component';
import swal from 'sweetalert2';
import { OrderPlacedComponent } from '../../popus/order-placed/order-placed.component';
import { CargosAdicionalesComponent } from '../../popus/cargos-adicionales/cargos-adicionales.component';
import { NewPoComponent } from 'src/app/shared/products-carousel/new-po/new-po.component';
import { EnumMensajes, EnumSiNo } from 'src/app/enumeration/enumeration';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeleteComponent } from '../../popus/delete/delete.component';

@Component({
  selector: 'app-checkout-standing',
  templateUrl: './checkout-standing.component.html',
  styleUrls: ['./checkout-standing.component.scss']
})
export class CheckoutStandingComponent implements OnInit {

  total = 0;
  grandTotal = 0;
  cartItemCount = 0;
  cartItemCountTotal = 0;
  date: any;
  dateT: any;
  c: any;
  destinoSeleccionado: Destino;
  camionSeleccionado: Camion;
  marcacionSleccionada: any;
  destinoSeleccionadoT: Destino;
  marcacionSleccionadaT: any;
  boxesEB: number = 0;
  boxesQB: number = 0;
  boxesHB: number = 0;
  boxesEBT: number = 0;
  boxesQBT: number = 0;
  boxesHBT: number = 0;
  countBoxesTruckingHB: number = 0;
  countBoxesTruckingQB: number = 0;
  countBoxesTruckingEB: number = 0;
  diferentDestination: boolean = true;
  dateNowInit: string;
  dateNowInitT: string;
  updateDate: any;
  updateDateT: any;
  tipoCliente: number;
  truckingCharges: number;
  resultadoProveedor: FincaPreferida[] = [];
  cajaStanding: Caja[] = [];
  cajaStandingT: Caja[] = [];
  totalCajas: number = 0;
  totalCajasSolidas: number = 0;
  totalCajasTropicales: number = 0;
  cantidadPorLote: number = 0;
  precioPorLote: number = 0;
  precioPorCaja: number = 0;
  contadorPorLote = 0;
  observacion: string = '';
  destinoFinal: Destino[] = [];
  selectDestino: Destino[] = this.destinoFinal;
  mostrarDestino = [];
  po: Destino[] = [];
  contieneTropical: string = EnumSiNo.N;
  mensajetropical = EnumMensajes.TROPICAL;

  constructor(public appService: AppService, public formBuilder: FormBuilder, private router: Router, public dialog: MatDialog,
    public datePipe: DatePipe,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService) {
  }

  //false hub
  //true standing
  ngOnInit() {
    localStorage.removeItem("_lsIndividualC");
    this.verificarContenido(true);
    this._calcularFechaEnvio();
    this._seleccionarMarcacion();
    this._calculosCajas(true);
    this.appService.contadorCarrito();
    this.totalCajas = this.appService._contadorCarritoPorTipoCaja(true)
    this.appService._botonMenuSeleccionado(this.router.url);
  }

  verificarContenido(condicion: boolean) {

    for (let caja of this.appService.Data.cartListCaja) {
      if (caja.stadingOrder === condicion) {
        if (this.appService.tropfilter.includes(caja.verificarVariedadCaja.toUpperCase())) {
          this.contieneTropical = EnumSiNo.S;
          break;
        }
      }
    }
    for (let caja of this.appService.Data.cartListCaja) {
      if (caja.stadingOrder === condicion) {
        if (this.appService.tropfilter.includes(caja.verificarVariedadCaja.toUpperCase())) {
          this.totalCajasTropicales++;
        } else {
          this.totalCajasSolidas++;
        }
      }
    }
  }

  _calculosCajas(condicion: boolean) {
    this.appService._calcularTotalCajaPorTipoCarrito(condicion);
    this.total = 0;
    this.grandTotal = 0;
    this.cartItemCount = 0;
    this.cartItemCountTotal = 0;
    this.appService.Data.cartListCaja.forEach(caja => {
      if (caja.stadingOrder === condicion) {
        this.total += caja.totalPrecio;
        this.grandTotal += caja.totalPrecio;
        this.cartItemCount += 1;
        this.cartItemCountTotal += 1;
        caja.mostrarDetalle = false;
      }
    });

    var resultado = this.appService._consultarSiEsFloristeria();

    if (resultado === EnumSiNo.N) {
      this._getTotalCajaConCargosAdicionales(condicion);
    }

    if (resultado === EnumSiNo.S) {
      this._getCargosAdicionalesPorFloristeria(condicion);
    }

    this.appService.addCartLocalStorage();
  }

  _calcularFechaEnvio() {
    var datePipe = new DatePipe("en-US");
    this.date = new FormControl(new Date(localStorage.getItem('_ls_dateConecctionStading')));
    this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');

    if (this.contieneTropical == EnumSiNo.S) {
      this.dateT = new FormControl(new Date(localStorage.getItem('_ls_dateConecctionStadingT')));
      this.dateNowInitT = datePipe.transform(this.dateT.value, 'yyyy-MM-dd')
    }
    
  }

  _seleccionarMarcacion() {
    this.camionSeleccionado = JSON.parse(sessionStorage.getItem('CamionStading'));
    this.destinoSeleccionado = JSON.parse(sessionStorage.getItem("DestinoStading"));
    this.marcacionSleccionada = JSON.parse(sessionStorage.getItem("MarcacionStading"));

    this.destinoSeleccionadoT = JSON.parse(sessionStorage.getItem("DestinoStadingT"));
    this.marcacionSleccionadaT = JSON.parse(sessionStorage.getItem("MarcacionStadingT"));

    this._obtenerDestinosPorMarcacion(this.marcacionSleccionada.pk.codigoMarcacion);
  }


  _getTotalCajaConCargosAdicionales(condicion: boolean) {
    var usuario = JSON.parse(localStorage.getItem("Usuario"));
    if (JSON.parse(localStorage.getItem("Usuario")) != undefined) {
      this.appService._getCargosAdicionalesMarcacion(usuario.codigoPersona).subscribe(data => {
        this._iniciarVariables();
        data.forEach(item => {
          if (item.tipoCaja == "EB") { this.boxesEB = item.valorEnvio }
          if (item.tipoCaja == "HB") { this.boxesHB = item.valorEnvio }
          if (item.tipoCaja == "QB") { this.boxesQB = item.valorEnvio }
        });
        this._calculosCartListCajas(condicion);
      });
    }

    if (usuario.codigoClientePadre === undefined || usuario.codigoClientePadre === null) {
      var marcacion;
      if (condicion === false) { marcacion = JSON.parse(sessionStorage.getItem("Marcacion")) }
      if (condicion === true) { marcacion = JSON.parse(sessionStorage.getItem("MarcacionStading")); }
      this.appService._getCargasTransportePorMarcacion(marcacion.pk.codigoMarcacion).subscribe(data => {
        this._iniciarVariables();
        localStorage.setItem("ls_cargos", JSON.stringify(data));
        var resultado = JSON.parse(localStorage.getItem("ls_cargos"));
        if (resultado.length > 1) {
          resultado.forEach(item => {
            if (item.tipoCaja == "EB") { this.boxesEB = item.valorEnvio }
            if (item.tipoCaja == "HB") { this.boxesHB = item.valorEnvio }
            if (item.tipoCaja == "QB") { this.boxesQB = item.valorEnvio }
          });
        }
        this._calculosCartListCajas(condicion);
      });
    }

  }

  _iniciarVariables() {
    this.boxesEB = 0;
    this.boxesQB = 0;
    this.boxesHB = 0;
    this.boxesEBT = 0;
    this.boxesQBT = 0;
    this.boxesHBT = 0;
    this.countBoxesTruckingEB = 0;
    this.countBoxesTruckingQB = 0;
    this.countBoxesTruckingHB = 0;
    this.appService.totalTuckingBoxes = 0;
    this.appService.totalWithTruckiBoxes = 0;
  }

  _calculosCartListCajas(condicion) {
    this.appService.Data.cartListCaja.forEach(data => {
      if (data.stadingOrder == condicion) {
        if (data.tipoCaja == 'EB') {
          this.countBoxesTruckingEB += data.cantidadIngresada;
        }
        if (data.tipoCaja == 'HB') {
          this.countBoxesTruckingHB += data.cantidadIngresada;
        }
        if (data.tipoCaja == 'QB') {
          this.countBoxesTruckingQB += data.cantidadIngresada;
        }
      }
    });
    this.boxesEBT = this.boxesEB * this.countBoxesTruckingEB;
    this.boxesQBT = this.boxesQB * this.countBoxesTruckingQB;
    this.boxesHBT = this.boxesHB * this.countBoxesTruckingHB;

    setTimeout(() => {
      this.appService.totalTuckingBoxes = this.boxesEBT + this.boxesQBT + this.boxesHBT;
      this.appService.totalWithTruckiBoxes = this.grandTotal + this.appService.totalTuckingBoxes;
    }, 20);
  }

  _getCargosAdicionalesPorFloristeria(condicion: boolean) {
    this.appService.esFloristeria = EnumSiNo.S;
    var camion = JSON.parse(sessionStorage.getItem("CamionStading"));
    var marcacion = JSON.parse(sessionStorage.getItem("MarcacionStading"));
    this.appService._getCargosPorFloristeria(camion.codigoCamion, marcacion.pk.codigoMarcacion).subscribe(data => {
      this.cantidadPorLote = data[0].cantidadLote;
      this.precioPorLote = data[0].precioLote;
      this.precioPorCaja = data[0].precioCaja;
      this._calcularTotalCargosPorFloristeria(condicion);
    });
  }

  _calcularTotalCargosPorFloristeria(condicion: boolean) {

    this.appService.totalValorFloristeria = 0;
    this.appService.totalTuckingBoxes = 0;
    this.contadorPorLote = 0;
    this.countBoxesTruckingEB = 0;
    this.countBoxesTruckingHB = 0;
    this.countBoxesTruckingQB = 0;

    for (let itemBoxes of this.appService.Data.cartListCaja) {
      if (itemBoxes.stadingOrder === condicion) {
        if (itemBoxes.tipoCaja == 'EB') {
          this.countBoxesTruckingEB += itemBoxes.cantidadIngresada;
        }
        if (itemBoxes.tipoCaja == 'QB') {
          this.countBoxesTruckingQB += itemBoxes.cantidadIngresada;
        }
        if (itemBoxes.tipoCaja == 'HB') {
          this.countBoxesTruckingHB += itemBoxes.cantidadIngresada;
        }
        this.contadorPorLote += itemBoxes.cantidadIngresada;
      }
    }

    this.boxesEBT = this.boxesEB * this.countBoxesTruckingEB;
    this.boxesQBT = this.boxesQB * this.countBoxesTruckingQB;
    this.boxesHBT = this.boxesHB * this.countBoxesTruckingHB;

    if (this.contadorPorLote > this.cantidadPorLote) {
      var cajasAdicionalesPorCobrar = this.contadorPorLote - this.cantidadPorLote;
      this.appService.totalValorFloristeria = this.precioPorLote;
      for (let i = 0; i < cajasAdicionalesPorCobrar; i++) {
        this.appService.totalValorFloristeria += this.precioPorCaja;
      }
    } else if (this.contadorPorLote <= this.cantidadPorLote && this.contadorPorLote > 0) {
      this.appService.totalValorFloristeria = this.precioPorLote;
    }

    this.appService.totalTuckingBoxes = this.appService.totalValorFloristeria;
    this.appService.totalWithTruckiBoxes = this.grandTotal + this.appService.totalTuckingBoxes;

  }

  _fincaPreferida(fincaPreferida, caja: Caja) {
    this.appService.getProveedoresConCodigo().subscribe(data => {
      this.resultadoProveedor = data.filter(x => x.nombresProveedor === fincaPreferida);
      if (this.resultadoProveedor.length > 0) {
        const index = this.appService.Data.cartListCaja.indexOf(caja);
        if (index !== -1) {
          this.appService.Data.cartListCaja.splice(index, 1);
          caja.fincaPreferida = fincaPreferida;
          caja.codigoProveedorWS = this.resultadoProveedor[0].codigoProveedor;
          // inserta en la misma posicion que elimino del objeto agregando un nuevo parametro
          this.appService.Data.cartListCaja.splice(index, 0, caja);
        }
      }
    });
  }

  _placeOrder() {

    if (sessionStorage.getItem('CamionStading') == null
      || sessionStorage.getItem('CamionStading') == 'undefined'
      || sessionStorage.getItem('DestinoStading') == null
      || sessionStorage.getItem('DestinoStading') == 'undefined'
      || sessionStorage.getItem('MarcacionStading') == null
      || sessionStorage.getItem('MarcacionStading') == 'undefined'
    ) {
      const dialogRef = this.dialog.open(DeleteComponent, {
        data: { mensaje: 'You must assign a destination and truck', mostrarBoton: true },
        panelClass: 'delete-boxes'
      });
      return;
    }

    this.cajaStanding = this.appService.Data.cartListCaja.filter(x => x.stadingOrder && !this.appService.tropfilter.includes(x.variedades[0].producto));
    this.cajaStandingT = this.appService.Data.cartListCaja.filter(x => x.stadingOrder && this.appService.tropfilter.includes(x.variedades[0].producto));

    if (this.appService.totalWithTruckiBoxes <= 0) { return }
    if (this.cajaStanding.length == 0) { return; }

    setTimeout(() => {
      this.spinner.show();
    }, 20)

    this.c = JSON.parse(localStorage.getItem('Usuario'));

    if (this.c.codigoClientePadre != null || this.c.codigoClientePadre != undefined) {
      this.tipoCliente = 0;
      if (this.boxesEBT + this.boxesQBT + this.boxesHBT === 0) {
        this.truckingCharges = 0;
      } else {
        this.truckingCharges = this.boxesEBT + this.boxesQBT + this.boxesHBT;
      }
    }
    else {
      this.truckingCharges = 0;
      this.tipoCliente = 1;
    }

    var datePipe = new DatePipe("en-US");
    var fechaFormataedaT
    var fechaFormataeda = datePipe.transform(localStorage.getItem('_ls_dateConecctionStading'), 'dd-MM-yyyy');

    if (this.contieneTropical == EnumSiNo.S) fechaFormataedaT = datePipe.transform(localStorage.getItem('_ls_dateConecctionStadingT'), 'dd-MM-yyyy');
    let ordenT: Orden = null;
    let orden: Orden = null;

    orden = new Orden(
      null,
      this.c.codigoClientePadre != undefined ? this.c.codigoClientePadre : this.c.codigoPersona,
      this.c.codigoClientePadre != undefined ? this.c.codigoPersona : null,
      this.updateDate != undefined ? this.updateDate : fechaFormataeda,
      this.marcacionSleccionada.codigoSeleccion,
      this.destinoSeleccionado.nombre,
      this.totalCajasSolidas,
      this.appService.totalWithTruckiBoxes,
      'ACT',
      this.camionSeleccionado.nombre,
      this.cajaStanding,
      this.c.codigoClientePadre,
      this.appService.totalTuckingBoxes,
      this.tipoCliente,
      '',
      this.c.esFloristeria,
      '*Standing Order*' + this.observacion
    );
    if (this.contieneTropical != 'N') {
      let AditionalInfoT = JSON.parse(sessionStorage.getItem("AditionalInfoStadingT"));
      let MarcacionT = JSON.parse(sessionStorage.getItem("MarcacionStadingT"));
      let DestinoT = JSON.parse(sessionStorage.getItem("DestinoStadingT"));
      let aditionalInfoString = this.setAditionalInfoString(AditionalInfoT, DestinoT.nombre);
      ordenT = new Orden(
        null,
        this.c.codigoClientePadre != undefined ? this.c.codigoClientePadre : this.c.codigoPersona,
        this.c.codigoClientePadre != undefined ? this.c.codigoPersona : null,
        this.updateDateT != undefined ? this.updateDateT : fechaFormataedaT,
        MarcacionT.codigoSeleccion,
        DestinoT.nombre,
        this.totalCajasTropicales,
        this.appService.totalWithTruckiBoxes,
        'ACT',
        'FEDEX',
        this.cajaStandingT,
        this.c.codigoClientePadre,
        this.appService.totalTuckingBoxes,
        this.tipoCliente,
        '',
        this.c.esFloristeria,
        '*Standing Order Tropical Flowers*' + aditionalInfoString + this.observacion
      );
    }

    if (!orden.codigoClientePadre) { orden.codigoClientePadre = 0; }
    if (ordenT != null) { if (!ordenT.codigoClientePadre) { ordenT.codigoClientePadre = 0; } }
    if (orden != null) {
      //console.log(dataclient);
      //return;
    }
    this.appService.registrarCompra(orden).subscribe(
      (data: any) => {
        if (this.contieneTropical != 'N') { this.appService.registrarCompra(ordenT).subscribe(() => { }); }
        this.appService._removerOrdenPorTipoCaja(this.cajaStanding);
        this.appService._removerOrdenPorTipoCaja(this.cajaStandingT);
        if (this.appService.Data.cartListCaja.length > 0) {
          this.appService.addCartLocalStorage();
          this.appService.contadorCarrito();
        } else {
          this.appService.Data.totalPrice = 0;
          this.appService.totalWithTruckiBoxes = 0;
          this.appService.totalTuckingBoxes = 0;
          this.totalCajas = 0;
          this.appService.Data.cartListCaja = [];
          localStorage.removeItem("Data");
        }
        this.spinner.hide();
        const dialogRef = this.dialog.open(OrderPlacedComponent, {
          data: {
            mensaje: EnumMensajes.ORDERECEIVED,
            mensaje2:EnumMensajes.ORDERECEIVED2,
            estado: EnumMensajes.PENDINGORDERSTADING
          },
          panelClass: 'order-placed'
        });
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.router.navigate(['/home']);
          } else {
            this.router.navigate(['/account/orders']);
          }
        });
      },
      (err: any) => {
        if (err.status != null || err.status != undefined) {
          this.spinner.hide();
          this.snackBar.open(EnumMensajes.ERRORSERVER, '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      });

  }

  _continuarCompra() {
    this.router.navigate(['home']);
  }

  _updateDate(event, tipo) {
    var datePipe = new DatePipe("en-US");
    if (tipo != 'T') {
      localStorage.removeItem("_ls_dateConecctionStading");
      localStorage.setItem("_ls_dateConecctionStading", event.value);
      this.updateDate = datePipe.transform(event.value, 'dd-MM-yyyy');
    } else {
      localStorage.removeItem("_ls_dateConecctionStadingT");
      localStorage.setItem("_ls_dateConecctionStadingT", event.value);
      this.updateDateT = datePipe.transform(event.value, 'dd-MM-yyyy');
    }


  }

  _cargosAdicionales() {
    var dataValue = "CART"
    const dialogRef = this.dialog.open(CargosAdicionalesComponent, {
      data: { value: dataValue, condicion: true },
      panelClass: 'costo-envio'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) { }
    });
  }

  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  _informationDialog(marcacion, tipo) {
    var camionNombre = '';
    if (this.camionSeleccionado == undefined) {
      camionNombre = 'SN';
    } else {
      camionNombre = this.camionSeleccionado.nombre;
    }
    const dialogRef = this.dialog.open(InformationComponent, {
      data: { data: 'S', marcacion: marcacion, camion: camionNombre, pagina: 'STD', tipo: tipo },
      panelClass: 'information'
    });
    dialogRef.afterClosed().subscribe(respuesta => {
      // if (respuesta != null) {
      this._actualizarDatos(respuesta);
      // }
    });
  }


  _actualizarDatos(respuesta) {
    this.camionSeleccionado = JSON.parse(sessionStorage.getItem('CamionStading'));
    this.destinoSeleccionado = JSON.parse(sessionStorage.getItem("DestinoStading"));
    this.marcacionSleccionada = JSON.parse(sessionStorage.getItem("MarcacionStading"));
    this.destinoSeleccionadoT = JSON.parse(sessionStorage.getItem("DestinoStadingT"));
    this.marcacionSleccionadaT = JSON.parse(sessionStorage.getItem("MarcacionStadingT"));
    this._calculosCajas(true);
    this._calcularFechaEnvio();
  }

  _obtenerDestinosPorMarcacion(codigoMarcacion) {
    this.appService.getDestinos(codigoMarcacion).subscribe((data: any) => {
      this.mostrarDestino = [];
      this.destinoFinal = [];
      this.selectDestino = [];
      this.po = data;

      var sessionDes = JSON.parse(sessionStorage.getItem("DestinoStading"));
      var itemDestino: Destino;
      itemDestino = this.po.find(x => x.nombre == sessionDes.nombre);
      this.mostrarDestino.push(this.po[0].nombre);
      this.destinoFinal.push(...this.po);
      this.selectDestino = this.destinoFinal;
      this.asignarDestinoPorDefaultOrden(itemDestino);
    });
  }

  asignarDestinoPorDefaultOrden(destino: Destino) {
    for (var index = 0; index < this.appService.Data.cartListCaja.length; index++) {
      const caja = this.appService.Data.cartListCaja[index];
      if (caja.stadingOrder) {
        if (caja.destinoSeleccionado === null) {
          caja.destinoSeleccionado = destino;
          caja.destinoNombre = destino.nombre;
        }
      }
    }
    this.appService.addCartLocalStorage();
  }

  asignarDestinoPorCaja(caja: Caja) {
    const dialogRef = this.dialog.open(NewPoComponent, {
      panelClass: 'nuevo-destino',
      data: { marcacion: this.marcacionSleccionada.pk.codigoMarcacion, tipo: 'SELECT PO' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        caja.destinoSeleccionado = result;
        caja.destinoNombre = result.nombre;
        this.appService.addCartLocalStorage();
      }
    });
  }
  setAditionalInfoString(info, po) {
    let str: string = '';
    str = `
      `+ 'Client name: ' + info.nombreEtiqueta + `
      `+ 'Address: ' + info.direccionEtiqueta + `
      `+ 'City / State / Zip: ' + info.ciudad + '/' +
      info.destino + '/' +
      info.codigoPostal + `
      `+ 'Tel: ' + info.telefonoEtiqueta + `
      `+ 'PO: ' + po + `
      `;
    return str;
  }
}
