import { filter } from 'rxjs/operators';

import { ProductDetallesDialogComponent } from './../../../shared/products-carousel/product-detalles-dialog/product-detalles-dialog.component';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { Caja, Talla, Variedad } from 'src/app/app.modelsWebShop';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { EditComponent } from '../edit/edit.component';
import { DeleteComponent } from '../delete/delete.component';
import { CargosAdicionalesComponent } from '../cargos-adicionales/cargos-adicionales.component';
import { EnumMensajes, EnumPagina, EnumSiNo } from 'src/app/enumeration/enumeration';


@Component({
  selector: 'app-carritodetalle',
  templateUrl: './carritodetalle.component.html',
  styleUrls: ['./carritodetalle.component.scss']
})
export class CarritodetalleComponent implements OnInit {
  total = 0;
  grandTotal = 0;
  cartItemCount = 0;
  cartItemCountTotal = 0;
  boxesEB: number = 0;
  boxesQB: number = 0;
  boxesHB: number = 0;
  boxesEBT: number = 0;
  boxesQBT: number = 0;
  boxesHBT: number = 0;
  cartItemTropicalCount: number = 0;
  cartItemNoTropicalCount: number = 0;
  countBoxesTruckingHB: number = 0;
  countBoxesTruckingQB: number = 0;
  countBoxesTruckingEB: number = 0;
  tallaGeneral: Talla = null;
  activarTab: boolean = true;
  cantidadPorLote: number = 0;
  precioPorLote: number = 0;
  precioPorCaja: number = 0;
  contadorPorLote = 0;
  mixBoxesBelow: boolean = false;
  tropicalBelowH: boolean = false;
  tropicalBelowS: boolean = false;

  constructor(public appService: AppService, public dialog: MatDialog,
    public router: Router, public dialogRef: MatDialogRef<CarritodetalleComponent>) {

  }
  // FALSE => HUB
  // TRUE  => STANDING
  ngOnInit() {
    setTimeout(() => {
      this.appService.setCondicionTab = false;
      this._calculosCajas(false)
      this._varietiesNoRepeated(this.appService.Data.cartListCaja[0].variedades)
      if (this.appService.Data.cartListCaja.length > 0) {
        let ncajasStnd = this.appService.Data.cartListCaja.filter(x => x.stadingOrder == true).length;
        let ncajasHub = this.appService.Data.cartListCaja.filter(x => x.stadingOrder == false).length;
        if (ncajasHub == 0 && ncajasStnd > 0) {
          this._activarTab(false)
        }
        // var href = this.router.url;
        // if (href == '/home') { this._activarTab(true) }
        // if (href == '/stading') { this._activarTab(false) }
      }
    }, 20)
  }

  _calculosCajas(condicion: boolean) {
    this.appService._calcularTotalCajaPorTipoCarrito(condicion)
    this.total = 0;
    this.grandTotal = 0;
    this.cartItemCount = 0;
    this.cartItemCountTotal = 0;
    this.appService.totalTuckingBoxes = 0;
    this.cartItemTropicalCount = 0;
    this.cartItemNoTropicalCount = 0;
    this.mixBoxesBelow = false;
    this.appService.Data.cartListCaja.forEach(caja => {
      if (caja.stadingOrder === condicion) {
        this.total += caja.totalPrecio;
        this.grandTotal += caja.totalPrecio;
        this.cartItemCount += 1;
        this.cartItemCountTotal += 1;
        caja.mostrarDetalle = false;
        if (caja.variedades.length > 1 && caja.tipoAgrega == 'B') {
          this.mixBoxesBelow = true;
        }
        if (caja.tipoAgrega == 'C') {
          if (this.appService.tropfilter.includes(caja.variedades[0].producto)) {
            this.cartItemTropicalCount++;
          } else {
            this.cartItemNoTropicalCount++;
          }
        }
      }
    });


    var resultado = this.appService._consultarSiEsFloristeria();
    if (resultado === 'N') {
      this._getTotalCajaConCargosAdicionales(condicion)
    }
    if (resultado === 'S') {
      this._getCargosAdicionalesPorFloristeria(condicion)
    }
    this.appService.addCartLocalStorage();
  }

  _getTotalCajaConCargosAdicionales(condicion: boolean) {
    var usuario = JSON.parse(localStorage.getItem("Usuario"));
    if (JSON.parse(localStorage.getItem("Usuario")) != undefined) {
      this.appService._getCargosAdicionalesMarcacion(usuario.codigoPersona).subscribe(data => {
        this.iniciarVariables();
        data.forEach(item => {
          if (item.tipoCaja == "EB") { this.boxesEB = item.valorEnvio }
          if (item.tipoCaja == "HB") { this.boxesHB = item.valorEnvio }
          if (item.tipoCaja == "QB") { this.boxesQB = item.valorEnvio }
        });
        this._calculosCartListCajas(condicion);
      });
    }

    //calculos de cajas cuando cambia de marcaion en la panatalla (information)
    if (usuario.codigoClientePadre === undefined || usuario.codigoClientePadre === null) {
      var marcacion;
      if (condicion === false) { marcacion = JSON.parse(sessionStorage.getItem("Marcacion")); }
      if (condicion) { marcacion = JSON.parse(sessionStorage.getItem("MarcacionStading")); }

      this.appService._getCargasTransportePorMarcacion(marcacion.pk.codigoMarcacion).subscribe(data => {
        this.iniciarVariables();
        localStorage.setItem("ls_cargos", JSON.stringify(data))
        if (data.length > 1) {
          data.forEach(item => {
            if (item.tipoCaja == "EB") { this.boxesEB = item.valorEnvio }
            if (item.tipoCaja == "HB") { this.boxesHB = item.valorEnvio }
            if (item.tipoCaja == "QB") { this.boxesQB = item.valorEnvio }
          });
        }
        this._calculosCartListCajas(condicion);
      });
    }

  }

  iniciarVariables() {
    this.boxesEB = 0;
    this.boxesQB = 0;
    this.boxesHB = 0;
    this.boxesEBT = 0;
    this.boxesQBT = 0;
    this.boxesHBT = 0;
    this.countBoxesTruckingEB = 0;
    this.countBoxesTruckingQB = 0;
    this.countBoxesTruckingHB = 0;
    this.appService.totalTuckingBoxes = 0;
    this.appService.totalWithTruckiBoxes = 0;
  }

  _calculosCartListCajas(condicion) {
    this.appService.Data.cartListCaja.forEach(data => {
      if (data.stadingOrder == condicion) {
        if (data.tipoCaja == 'EB') {
          this.countBoxesTruckingEB += data.cantidadIngresada;
        }
        if (data.tipoCaja == 'HB') {
          this.countBoxesTruckingHB += data.cantidadIngresada;
        }
        if (data.tipoCaja == 'QB') {
          this.countBoxesTruckingQB += data.cantidadIngresada;
        }
      }
    });

    this.boxesEBT = this.boxesEB * this.countBoxesTruckingEB;
    this.boxesQBT = this.boxesQB * this.countBoxesTruckingQB;
    this.boxesHBT = this.boxesHB * this.countBoxesTruckingHB;
    this.appService.totalTuckingBoxes = this.boxesEBT + this.boxesQBT + this.boxesHBT;
    this.appService.totalWithTruckiBoxes = this.grandTotal + this.appService.totalTuckingBoxes;
  }

  _getCargosAdicionalesPorFloristeria(condicion: boolean) {
    this.appService.esFloristeria = EnumSiNo.S;
    let camion = JSON.parse(sessionStorage.getItem("Camion"));
    let marcacion = JSON.parse(sessionStorage.getItem("Marcacion"));
    this.appService._getCargosPorFloristeria(camion.codigoCamion, marcacion.pk.codigoMarcacion).subscribe(data => {
      this.cantidadPorLote = data[0].cantidadLote;
      this.precioPorLote = data[0].precioLote;
      this.precioPorCaja = data[0].precioCaja;
      this._calcularTotalCargosPorFloristeria(condicion);
    });
  }

  _calcularTotalCargosPorFloristeria(condicion: boolean) {
    this.appService.totalValorFloristeria = 0;
    this.appService.totalTuckingBoxes = 0;
    this.contadorPorLote = 0;
    this.countBoxesTruckingEB = 0;
    this.countBoxesTruckingHB = 0;
    this.countBoxesTruckingQB = 0;
    for (let itemBoxes of this.appService.Data.cartListCaja) {
      if (itemBoxes.stadingOrder === condicion) {
        if (itemBoxes.tipoCaja == 'EB') {
          this.countBoxesTruckingEB += itemBoxes.cantidadIngresada;
        }
        if (itemBoxes.tipoCaja == 'QB') {
          this.countBoxesTruckingQB += itemBoxes.cantidadIngresada;
        }
        if (itemBoxes.tipoCaja == 'HB') {
          this.countBoxesTruckingHB += itemBoxes.cantidadIngresada;
        }
        this.contadorPorLote += itemBoxes.cantidadIngresada;
      }
    }

    this.boxesEBT = this.boxesEB * this.countBoxesTruckingEB;
    this.boxesQBT = this.boxesQB * this.countBoxesTruckingQB;
    this.boxesHBT = this.boxesHB * this.countBoxesTruckingHB;

    if (this.contadorPorLote > this.cantidadPorLote) {
      let cajasAdicionalesPorCobrar = this.contadorPorLote - this.cantidadPorLote;
      this.appService.totalValorFloristeria = this.precioPorLote;
      for (let i = 0; i < cajasAdicionalesPorCobrar; i++) {
        this.appService.totalValorFloristeria += this.precioPorCaja;
      }
    } else if (this.contadorPorLote <= this.cantidadPorLote && this.contadorPorLote > 0) {
      this.appService.totalValorFloristeria = this.precioPorLote;
    }

    this.appService.totalTuckingBoxes = this.appService.totalValorFloristeria;
    this.appService.totalWithTruckiBoxes = this.grandTotal + this.appService.totalTuckingBoxes;

  }

  _eliminarCaja(caja: Caja, condicion: boolean) {
    const index: number = this.appService.Data.cartListCaja.indexOf(caja);
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: { titulo: 'Caution', mensaje: EnumMensajes.DELETECLIENTE },
      panelClass: 'delete-boxes'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (index !== -1) {
          this.appService.Data.cartListCaja.splice(index, 1);
          this.countBoxesTruckingEB = 0;
          this.countBoxesTruckingQB = 0;
          this.countBoxesTruckingHB = 0;
          this._calculosCajas(condicion);
          this.appService.contadorCarrito();
        }
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  _checkout() {
    if (this.appService.totalWithTruckiBoxes <= 0) { return }
    this.dialogRef.close();
    this.router.navigate(['/checkout']);
  }

  _checkoutStading() {
    if (this.appService.totalWithTruckiBoxes <= 0) { return }
    this.dialogRef.close();
    this.router.navigate(['/checkout/standing']);
  }

  cargosAdicionales() {
    var dataValue = "CART";
    const dialogRef = this.dialog.open(CargosAdicionalesComponent, {
      data: { value: dataValue, condicion: this.appService.setCondicionTab },
      panelClass: 'costo-envio'
    });
  }

  editarCaja(caja) {
   // var pagina = this.activarTab == true ? EnumPagina.HUB : EnumPagina.STA;
    this.appService.agregarAcajaDesdeCarrito(caja)
   
    const index: number = this.appService.Data.cartListCaja.indexOf(caja);
      this.appService.Data.cartListCaja.splice(index, 1);
      this.countBoxesTruckingEB = 0;
      this.countBoxesTruckingQB = 0;
      this.countBoxesTruckingHB = 0;
      this._calculosCajas(false);
      this.appService.contadorCarrito();

      this.router.navigate(['/home/edit-mix-box']);
    
    // const dialogRef = this.dialog.open(EditComponent, {
    //   data: {
    //     caja: caja,
    //     tipoAgrega: tipoAgrega,
    //     verLista: 'CART',
    //     paginaRuta: pagina,
    //     mensajeTitulo: "Variety edit"
    //   },
    //   width: '1100px',
    //   height: '520px',
    //   panelClass: 'edit-productos'
    // });
    this.dialogRef.close();
    // dialogRef.afterClosed().subscribe(res => {
    //   if (res) {
    //     this.appService.activarEdicionVariedad = true
    //     this.appService.cambioMenu = null;
    //     if (this.activarTab == true) {
    //       this.router.navigate(['/home']);
    //     } else {
    //       this.router.navigate(['/stading']);
    //     }
    //   }
    // });
  }

  _editarTallaPorCaja(talla: Talla, caja: Caja, productOriginal: Caja[], index, condicion: boolean) {
    var variedadARemplazar = productOriginal[0].variedades
      .filter(x => x.caja === caja.tipoCaja && x.talla == talla.valor && x.tallaCm == talla.codigo)
    this.tallaGeneral = new Talla(talla.codigo, talla.valor);
    if (variedadARemplazar.length > 0) {
      const indexVariedadNueva = productOriginal[0].variedades.indexOf(variedadARemplazar[0])
      if (indexVariedadNueva != -1) {
        caja.variedades[0].indexPorVariedad = indexVariedadNueva;
        caja.tallasDeCaja = [variedadARemplazar[0].talla];
        let variedadAsignada = this._asignarVariedadNueva(variedadARemplazar, caja.variedades, talla)
        if (variedadAsignada != null) {
          this.appService.Data.cartListCaja[index].variedades.splice(0, 1);
          this.appService.Data.cartListCaja[index].variedades.splice(0, 0, variedadAsignada);
          this._calculosCajas(condicion);
        }
      }
    }
  }

  _editarCantidadPorCaja(valor, caja: Caja, productOriginal: Caja[], index, condicion: boolean) {
    if (valor.target.value == '' || valor.target.value == null) { return }
    if (this.tallaGeneral == null || this.tallaGeneral == undefined) {
      this.tallaGeneral = new Talla(caja.tallasCajaCm[0], caja.tallasDeCaja[0]);
    }
    var variedadARemplazar = productOriginal[0].variedades
      .filter(x => x.caja === caja.tipoCaja && x.talla == this.tallaGeneral.valor && x.tallaCm == this.tallaGeneral.codigo);
    if (variedadARemplazar.length > 0) {
      const indexVariedadNueva = productOriginal[0].variedades.indexOf(variedadARemplazar[0]);
      if (indexVariedadNueva != -1) {
        caja.variedades[0].indexPorVariedad = indexVariedadNueva;
        caja.tallasDeCaja = [this.tallaGeneral.valor];
        caja.tallasCajaCm = [this.tallaGeneral.codigo];
        caja.cantidadIngresada = parseInt(valor.target.value);
        var tallaActual = new Talla(caja.tallasCajaCm[0], caja.tallasDeCaja[0]);
        var variedadAsignada = this._asignarVariedadNueva(variedadARemplazar, caja.variedades, tallaActual);
        if (variedadAsignada != null) {
          this.appService.Data.cartListCaja[index].variedades.splice(0, 1);
          this.appService.Data.cartListCaja[index].variedades.splice(0, 0, variedadAsignada);
          this._calculosCajas(condicion);
          this.appService.contadorCarrito();
        }
      }
    }
  }

  _editarCajaSolida(valor, caja: Caja, condicion: boolean) {
    if (valor.target.value == '' || valor.target.value == null) {
      return;
    }
    const indexCajaSolida = this.appService.Data.cartListCaja.indexOf(caja)
    if (indexCajaSolida != -1) {
      this.appService.Data.cartListCaja.splice(indexCajaSolida, 1);
      caja.cantidadIngresada = parseInt(valor.target.value);
      this.appService.Data.cartListCaja.splice(indexCajaSolida, 0, caja),
        this._calculosCajas(condicion);
      this.appService.contadorCarrito();
    }
  }

  _asignarVariedadNueva(nuevaVariedad: Variedad[], anteriorVariedad: Variedad[], talla: Talla): Variedad {
    var variedad = new Variedad(
      anteriorVariedad[0].codigoVariedad,
      anteriorVariedad[0].nombreVariedad,
      anteriorVariedad[0].producto,
      anteriorVariedad[0].cantidadPorBunche,
      anteriorVariedad[0].caja,
      anteriorVariedad[0].cantidadPorCaja,
      talla.valor,
      talla.codigo,
      anteriorVariedad[0].imagenes,
      nuevaVariedad[0].precio,
      nuevaVariedad[0].precioSO,
      nuevaVariedad[0].precioFinca,
      nuevaVariedad[0].precioJv,
      nuevaVariedad[0].precioCliente,
      anteriorVariedad[0].stadingOrder,
      anteriorVariedad[0].cajaCombo,
      anteriorVariedad[0].cartCount,
      anteriorVariedad[0].precios,
      anteriorVariedad[0].codigosProveedor,
      anteriorVariedad[0].seguridad,
      anteriorVariedad[0].cajasCantidad,
      anteriorVariedad[0].disabled,
      anteriorVariedad[0].mostrarPrecioPorCaja,
      anteriorVariedad[0].cantidadPorCajaMixta,
      anteriorVariedad[0].sePuedeMezclar,
      anteriorVariedad[0].nombresProveedor,
      anteriorVariedad[0].disabledBox,
      anteriorVariedad[0].disabledBunches,
      anteriorVariedad[0].productOriginal,
      anteriorVariedad[0].tallasVariedadSeleccionada,
      anteriorVariedad[0].indexPorVariedad,
      anteriorVariedad[0].cajasPorVariedad,
      anteriorVariedad[0].cajaMinima,
      anteriorVariedad[0].fincaPreferida
    );
    return variedad;
  }

  _activarTab(valor: boolean) {
    this.activarTab = valor;
    if (this.activarTab) {
      this._calculosCajas(false)
      this.appService.setCondicionTab = false;
    } else {
      this._calculosCajas(true)
      this.appService.setCondicionTab = true;
    }
  }

  _verDetalleCajaCombo(caja) {
    const dialogRef = this.dialog.open(ProductDetallesDialogComponent, {
      data: { caja: caja, editar: false },
      panelClass: 'detalle-combo'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) { }
    });
  }

  _verMasProductos(caja: Caja) {
    const index = this.appService.Data.cartListCaja.indexOf(caja);
    if (index !== -1) {
      var pagina = this.activarTab == true ? EnumPagina.HUB : EnumPagina.STA;
      const dialogRef = this.dialog.open(EditComponent, {
        data:
        {
          caja: caja,
          tipoAgrega: "B",
          verLista: 'CART',
          paginaRuta: pagina,
          mensajeTitulo: EnumMensajes.MESSAGETITLE
        },
        width: '1100px',
        height: '520px',
        panelClass: 'edit-productos'
      });

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.appService.activarEdicionVariedad = true;
          this.appService.cambioMenu = null;
          if (this.activarTab == true) {
            this.router.navigate(['/home']);
          } else {
            this.router.navigate(['/stading']);
          }
          this.dialogRef.close();
        }
      });
    }
  }

  _varietiesNoRepeated(list: any) {
    let tr=0;

    return list.filter((v, i, a) => a.findIndex(t => (t.codigoVariedad === v.codigoVariedad && t.talla === v.talla)) === i);
  }

  _countVarietiesNoRepeat(v, list) {
    return list.filter(t => (t.codigoVariedad === v.codigoVariedad && t.talla === v.talla)).length;
  }

  _piceVarietiesNoRepeat(v, list) {
    return list.filter(t => (t.codigoVariedad === v.codigoVariedad && t.talla === v.talla)).map(a => a.precioCliente).reduce(function (a, b) {
      return a + b;
    });;
  }

}



