import { Colores, Destino, Filtro, Marcacion, PaginadorProducto } from './../../app.modelsWebShop';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Variedad, Caja } from 'src/app/app.modelsWebShop';
import { AppService } from 'src/app/app.service';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ClienteDTO } from 'src/app/app.models';
import { forEach } from '@angular/router/src/utils/collection';
import { AddedComponent } from 'src/app/pages/popus/added/added.component';
import { InformationComponent } from 'src/app/pages/popus/information/information.component';
import { Router } from '@angular/router';
import { DeleteComponent } from 'src/app/pages/popus/delete/delete.component';
import { DatePipe } from '@angular/common';
import { EnumPagina, EnumSiNo } from 'src/app/enumeration/enumeration';
import { SharedService } from '../service/shared.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-controls-box',
  templateUrl: './controls-box.component.html',
  styleUrls: ['./controls-box.component.scss']
})
export class ControlsBoxComponent implements OnInit {
  @Input() producto: Caja;
  @Input() indexVariedad: number;
  @Input() variedad: Variedad;
  @Input() type: string;
  @Input() TipoCaja: string
  @Input() imagen: any
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() limpiarOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitirPopupCamion: EventEmitter<any> = new EventEmitter<any>();
  @Input() DatosProductosIu: any
  @Input() cajaSeleccionada: string;
  @Input() paginaRuta: string
  @Input() marcacionSleccionada: Marcacion;
  @Input() camionSeleccionado;
  @Input() isTropical: boolean;
  dateNowInit: string;
  destinoSeleccionado: Destino;
  zonaHorariaEcuador;
  horarioDeEcuador = new Date();
  dateNews = new Date();
  productosPaginados: Array<Caja> = [];
  date: any;
  diaSemana = '';
  public count = 0;
  public align = 'center center';
  currentpage: any;
  coloresWebShop: Colores[] = [];
  coloresEncontrados: number = 0;
  datePipe;
  totalRegistros = 0;
  listaFiltros: Filtro[] = [];
  listaFiltrosFinales: Array<Filtro> = [];
  dias = [];
  nameInfo: string = '';
  addressInfo: string = '';
  cityInfo: string = '';
  stateInfo: string = '';
  zipInfo: string = '';
  phoneInfo: string = '';
  errorInfoTropical = false;

  paginado = new PaginadorProducto();

  isLoading = false

  isLoadingOne = false

  usuario: any;

  private subscription: Subscription;

  constructor(public appService: AppService, public snackBar: MatSnackBar,
    public dialog: MatDialog, public router: Router, private sharedService: SharedService,
    public dialogRef: MatDialogRef<InformationComponent>) {
    this.subscription = this.appService.eventoSeleccionaCamionYPo.subscribe(() => {
      this.increment()
    })
  }


  public DatosProductos = []
  public productos: Array<Caja> = [];
  @Input() cantidadCajas: number;

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {

    this.currentpage = this.router.url.replace('/', '');
    this.currentpage == 'home' ? this.currentpage = 'HUB' : this.currentpage = 'STD';
    this.layoutAlign();
  }

  public layoutAlign() {
    if (this.type === 'all') {
      this.align = 'space-between center';
    } else if (this.type === 'wish') {
      this.align = 'start center';
    } else {
      this.align = 'center center';
    }
  }

  _setTropicalInfo() {
    if (this.nameInfo == '' ||
      this.addressInfo == '' ||
      this.cityInfo == '' ||
      this.stateInfo == '' ||
      this.zipInfo == '' ||
      this.phoneInfo == '') { this.errorInfoTropical = true; return; } else { this.errorInfoTropical = false; }
    let str: any;
    str = {
      nombreEtiqueta: this.nameInfo,
      direccionEtiqueta: this.addressInfo,
      ciudad: this.cityInfo,
      destino: this.stateInfo,
      codigoPostal: this.zipInfo,
      telefonoEtiqueta: this.phoneInfo,
    }

    if (this.currentpage == 'HUB') { sessionStorage.setItem('AditionalInfoT', JSON.stringify(str)); }
    if (this.currentpage == 'STD') { sessionStorage.setItem('AditionalInfoStadingT', JSON.stringify(str)); }

    //Guardar PO
    let marcacionTropical;
    let destinoTropical;
    if (this.currentpage == 'HUB') {
      marcacionTropical = JSON.parse(sessionStorage.getItem("MarcacionT"));
      destinoTropical = JSON.parse(sessionStorage.getItem("DestinoT"));
    }
    if (this.currentpage == 'STD') {
      marcacionTropical = JSON.parse(sessionStorage.getItem("MarcacionStadingT"));
      destinoTropical = JSON.parse(sessionStorage.getItem("DestinoStadingT"));
    }
    destinoTropical.nombreCliente = str.nombreEtiqueta;
    destinoTropical.direccion = str.direccionEtiqueta;
    destinoTropical.ciudad = str.ciudad;
    destinoTropical.codigoDestino = str.destino;
    destinoTropical.codigoPostal = str.codigoPostal;
    destinoTropical.telefono = str.telefonoEtiqueta;

    this.appService.persistirDestino(destinoTropical, marcacionTropical.pk.codigoMarcacion).subscribe(
      (data: any) => {
        console.log("PO save:", data);
      });
  }

  public increment() {
    if (this.isTropical == true) {
      this._setTropicalInfo();
      if (!this.errorInfoTropical) {
        this.dialogRef.close('save')
      }
    }
    else {

      if (sessionStorage.getItem('Camion') == null
        || sessionStorage.getItem('Camion') == 'undefined'
        || sessionStorage.getItem('Destino') == null
        || sessionStorage.getItem('Destino') == 'undefined'
        || sessionStorage.getItem('Marcacion') == null
        || sessionStorage.getItem('Marcacion') == 'undefined'
      ) {
        const variablesPorAgregar = []
        variablesPorAgregar.push(this.producto.tallaSeleccionada)
        variablesPorAgregar.push(this.cantidadCajas)
        variablesPorAgregar.push(this.indexVariedad)
        variablesPorAgregar.push(this.producto.variedades[this.indexVariedad])
        variablesPorAgregar.push(this.producto)
        this.sharedService.respaldarDatosProductoAgregar(variablesPorAgregar)

        this.emitirPopupCamion.emit(variablesPorAgregar);
        return;
      }
    }


    if (this.sharedService.productoRespaldo != null) {
      const cantidadCajasRespaldo = this.sharedService.productoRespaldo[1];
      const variedadRespaldo = this.sharedService.productoRespaldo[3];
      const productoRespaldo = this.sharedService.productoRespaldo[4];
      this.cantidadCajas = cantidadCajasRespaldo
      this.producto = productoRespaldo
      this.imagen = this.producto.imagen
      this.variedad = this.actualizarPrecioVariedad(variedadRespaldo);
      this.sharedService.respaldarDatosProductoAgregar(null)
    } else {
      if (this.cantidadCajas == null || this.cantidadCajas == undefined) { return }
      let variedadBuscar = this.producto.variedades[this.indexVariedad];
      this.variedad = variedadBuscar;
    }

    if (!this.isTropical) {
      this.incrementVarios(this.variedad);
    }
    if (this.isTropical) {
      var camionNombre = '';
      if (this.camionSeleccionado == undefined) {
        camionNombre = 'SN';
      } else {
        camionNombre = this.camionSeleccionado.nombre
      }
      const dialogRef = this.dialog.open(InformationComponent, {
        data: { data: 'N', marcacion: this.marcacionSleccionada, camion: camionNombre, pagina: this.currentpage, tipo: 'T' },
        panelClass: 'information',
        disableClose: true // Evita el cierre al hacer clic fuera del cuadro de diálogo
        //maxHeight: '95vh'
      });
      dialogRef.afterClosed().subscribe(respuesta => {
        if (respuesta != null) {
          this.incrementVarios(this.variedad);
          // this.actualizarDatos();
          this.appService.guardarShippingInformation(
            this.marcacionSleccionada,
            this.camionSeleccionado,
            this.destinoSeleccionado,
            EnumPagina.HUB);
        }
      });
    }
  }

  public actualizarPrecioVariedad(variedad) {
    this.destinoSeleccionado = JSON.parse(sessionStorage.getItem('Destino'));
    const c: any = JSON.parse(localStorage.getItem('Usuario'));

    for (let z = 0; z < variedad.precios.length; z++) {
      const precio = variedad.precios[z];
      let totalAux = 0;
      let totalAuxJv = 0;

      if (precio.tipoPrecio === EnumSiNo.N) {

        let porcentajeSumar = 0;

        if (c.codigoClientePadre !== undefined) {
          porcentajeSumar = c.porcentajeSubcliente / 100;
        } else if (this.destinoSeleccionado !== null && this.destinoSeleccionado !== undefined
          && this.destinoSeleccionado.subcliente !== null
          && this.destinoSeleccionado.subcliente !== undefined
          && this.destinoSeleccionado.subcliente.marginSubcliente !== null
          && this.destinoSeleccionado.subcliente.marginSubcliente !== undefined) {
          porcentajeSumar = this.destinoSeleccionado.subcliente.marginSubcliente / 100;
        }

        const sumarPrecio = precio.precio * porcentajeSumar;
        const sumarPrecioJv = precio.precioJv * porcentajeSumar;

        precio.precio += sumarPrecio;
        precio.precioJv += sumarPrecioJv;

        if (precio.codigoTipoCliente === c.codigoTipoCliente) {
          variedad.precio = precio.precio;
          variedad.precioCliente = precio.precioCliente;
          variedad.precioJv = precio.precioJv;

          // if (element.combo === EnumSiNo.S) {
          //   variedad.cajaCombo = EnumSiNo.S;
          //   totalAux = variedad.precio * variedad.cantidadPorCaja;
          //   totalAuxJv = variedad.precioJv * variedad.cantidadPorCaja;
          //   element.totalPrecio += totalAux;
          //   element.totalPrecioJv += totalAuxJv;
          // }
        }
      }
    }
    return variedad
  }

  public actualizarDatos() {
    this.camionSeleccionado = JSON.parse(sessionStorage.getItem('Camion'));
    this.destinoSeleccionado = JSON.parse(sessionStorage.getItem("Destino"));
    this.marcacionSleccionada = JSON.parse(sessionStorage.getItem("Marcacion"));
    this._getCalcularDiaEntrega()
  }

  getColoresWebShop(numero: number) {
    this.appService.getColoresWebShop().subscribe(colores => {
      this.coloresEncontrados = colores.numRegistros
      this.coloresWebShop = JSON.parse(colores.json);
      this.coloresWebShop.forEach(x => {
        x.nombre = x.nombre.toLowerCase();
        x.estilo = 'c-' + x.nombre.toLocaleLowerCase().replace(' ', '-');
        x.select = EnumSiNo.N
      })
      this.coloresWebShop = this.coloresWebShop.sort(function (a, b) {
        return a.nombre.localeCompare(b.nombre);
      });
    });
  }

  async obtenerProductosPorTemporada() {

    this.getColoresWebShop(0);

    let dateConnection = localStorage.getItem('_ls_dateConecction');

    if (dateConnection != null || dateConnection != undefined) {
      this.date = new Date(dateConnection);

      this.datePipe = new DatePipe("en-US");
      this.paginado.fecha = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    }

    await this.getproductosWebShop();
  }

  getproductosWebShop() {
    this.isLoadingOne = true
    this.isLoading = true;
    this.appService.getProductosWebShopPost(this.paginado).subscribe(data => {

      this.totalRegistros = data.numRegistros;

      const c: any = JSON.parse(localStorage.getItem('Usuario'));
      const cajas: Caja[] = JSON.parse(data.json);
      this.productos = cajas.filter(x => x.combo == EnumSiNo.N);

      if (this.productos.length > 0) {

        this.appService.orderWebShop = c.orderWebShop === EnumSiNo.N ? EnumSiNo.N : EnumSiNo.S;
        for (let index = 0; index < this.productos.length; index++) {

          const element = this.productos[index];
          element.tallasDeCaja = [];
          element.tallasCajaCm = [];
          element.tallasFinales = [];
          element.cajasDisponiblesMixtas = [];
          element.imagen = this.appService.urlImagen + element.imagenes[0];
          element.stadingOrder = false;
          element.nombreProducto = element.variedades[0].nombreVariedad.toLowerCase() + " - " + element.variedades[0].producto.toLowerCase();
          for (let x = 0; x < element.variedades.length; x++) {
            const variedad = element.variedades[x];
            variedad.cajasPorVariedad = [];
            var buscarVariedadesPorTalla = element.variedades.filter(x => x.talla == variedad.talla);
            if (buscarVariedadesPorTalla.length > 0) {
              buscarVariedadesPorTalla.forEach(caja => {
                variedad.cajasPorVariedad.push({
                  caja: caja.caja,
                  valor: caja.cantidadPorCajaMixta
                })
              });
              var hash = {};
              variedad.cajasPorVariedad = variedad.cajasPorVariedad.filter(function (productoCaja) {
                var exists = !hash[productoCaja.caja];
                hash[productoCaja.caja] = true;
                return exists;
              });
            }
            element.cajasDisponiblesMixtas.push(variedad.cantidadPorCajaMixta);
            if (variedad.cantidadPorCaja > 0) {
              element.tallasDeCaja.push(variedad.talla);
              element.tallasCajaCm.push(variedad.tallaCm);
              element.tallasFinales.push({ codigo: variedad.tallaCm, valor: variedad.talla });
              if (element.combo == EnumSiNo.S) { element.totalPiezas += variedad.cantidadPorCaja; }
              variedad.stadingOrder = false;
              variedad.cajaCombo = EnumSiNo.N;
              variedad.disabledBox = false;
              variedad.disabledBunches = false;
              variedad.disabled = variedad.seguridad == "si" ? true : false;
              element.botonBox = EnumSiNo.S;
              element.botonBunches = variedad.mostrarPrecioPorCaja == 'no' ? EnumSiNo.N : EnumSiNo.S;
              for (let z = 0; z < variedad.precios.length; z++) {
                var precio = variedad.precios[z];
                var totalAux = 0;
                var totalAuxJv = 0;
                if (precio.tipoPrecio == EnumSiNo.N) {
                  if (c.codigoClientePadre != undefined) {
                    var porcentajeSumar = c.porcentajeSubcliente / 100;
                    var sumarPrecio = precio.precio * porcentajeSumar
                    var sumarPrecioJv = precio.precioJv * porcentajeSumar
                    precio.precio += sumarPrecio
                    precio.precioJv += sumarPrecioJv
                  }
                  if (c.codigoClientePadre === undefined && this.destinoSeleccionado != null) {
                    if (this.destinoSeleccionado.subcliente != null) {
                      if (this.destinoSeleccionado.subcliente.marginSubcliente != null && this.destinoSeleccionado.subcliente.marginSubcliente > 0) {
                        var porcentajeSumar = this.destinoSeleccionado.subcliente.marginSubcliente / 100;
                        var sumarPrecio = precio.precio * porcentajeSumar
                        var sumarPrecioJv = precio.precioJv * porcentajeSumar
                        precio.precio += sumarPrecio
                        precio.precioJv += sumarPrecioJv
                      }
                    }
                  }
                  if (precio.codigoTipoCliente === c.codigoTipoCliente) {
                    variedad.precio = precio.precio;
                    variedad.precioCliente = precio.precioCliente
                    variedad.precioJv = precio.precioJv
                    if (element.combo == EnumSiNo.S) {
                      variedad.cajaCombo = EnumSiNo.S
                      totalAux = variedad.precio * variedad.cantidadPorCaja
                      totalAuxJv = variedad.precioJv * variedad.cantidadPorCaja
                      element.totalPrecio += totalAux
                      element.totalPrecioJv += totalAuxJv
                    }
                  }
                }
              }
            }
          }
          var tallaOriginalMenor = element.tallasCajaCm[0];
          var cajaOriginalMenor = [];
          var tallasUnicas = Array.from(new Set(element.tallasCajaCm));
          var tallaStr = element.tallasDeCaja[0];
          var hash = {};
          element.tallasFinales = element.tallasFinales.filter(function (current) {
            var exists = !hash[current.valor];
            hash[current.valor] = true;
            return exists;
          });
          element.tallasCajaCm = tallasUnicas.sort((a, b) => a - b);
          element.tallasFinales.sort((a, b) => a.codigo + b.codigo);
          element.tallaSeleccionada = tallaStr;// + '/';
          if (element.variedades[0].producto == "ROSES" || element.variedades[0].producto == "GARDEN ROSES"
            || element.variedades[0].producto == "MAYRAS GARDEN ROSES" || element.variedades[0].producto == "SPRAY ROSES") {
            if (element.tallasFinales.filter(x => x.valor === "50 CM").length > 0) {
              tallaOriginalMenor = 50;
              tallaStr = "50 CM";
              element.tallasDeCaja = [];
              element.tallasDeCaja.push(tallaStr);
              element.tallaSeleccionada = tallaStr;// + '/';
            }
          }
          if (element.variedades[0].producto == "HYDRANGEA") {
            if (element.tallasFinales.filter(x => x.valor === 'SUPER SELECT').length > 0) {
              tallaOriginalMenor = 0;
              tallaStr = 'SUPER SELECT';
              element.tallasDeCaja = [];
              element.tallasDeCaja.push(tallaStr);
              element.tallaSeleccionada = tallaStr;// + '/';
            }
          }
          var variedadBusqueda = [];
          element.variedades.forEach(variedad => {
            if (variedad.cantidadPorCaja > 0) {
              if (variedad.tallaCm == tallaOriginalMenor && variedad.talla == tallaStr) {
                variedadBusqueda.push(variedad)
              }
            }
          });
          variedadBusqueda.forEach(item => {
            cajaOriginalMenor.push(item.cantidadPorCaja);
          });
          //debugger
          var minCaja = Math.max(...cajaOriginalMenor);
          element.cajasDisponiblesMixtas = Array.from(new Set(element.cajasDisponiblesMixtas));
          element.cajaSeleccionada = minCaja;
          element.indexVariedadSeleccionada = this.tallaProducto(tallaStr, element, minCaja, "N");
          element.indexPorTipoCaja = this.actualizarIndexPorTipoCaja(element, tallaStr);
          element.tallasFinales.sort((a, b) => a.codigo - b.codigo);

        }
        //function comparar(a, b) { return a - b; };
        this.listaFiltros = [];
        this.listaFiltrosFinales = [];
        for (let item of cajas) {
          this.listaFiltros.push({ tipo: item.variedades[0].nombreVariedad.toLowerCase(), valor: item.variedades[0].producto.toLowerCase(), seleccionado: 'N' })
          this.listaFiltrosFinales.push({ tipo: item.variedades[0].nombreVariedad, valor: item.variedades[0].producto, seleccionado: null })
        }
        // this._getProductosFiltro();
        // this._filterOptions();
        // this._getCategoriasAutocomplete();
        // this._obtenerColoresFiltro();
        // this._getColoresAutocomplete();
        //this.getColoresWebShop(5);`
        this.productosPaginados = this.productosPaginados.concat(this.productos);
      }
      this.isLoading = false;
      this.isLoadingOne = false;
    });
  }

  actualizarIndexPorTipoCaja(producto, talla: string): number {
    var indexCaja: number;
    const index: number = this.productos.indexOf(producto);
    const productoSeleccionado = this.productos[index];
    var resultadoCajaEB = productoSeleccionado.variedades.filter(x => x.caja === "EB" && x.talla === talla && x.cantidadPorCaja > 0)
    var resultadoCajaQB = productoSeleccionado.variedades.filter(x => x.caja === "QB" && x.talla === talla && x.cantidadPorCaja > 0)
    var resultadoCajaHB = productoSeleccionado.variedades.filter(x => x.caja === "HB" && x.talla === talla && x.cantidadPorCaja > 0)
    // si tiene qb selecciona el precio del bunche qb
    if (resultadoCajaQB.length > 0) {
      resultadoCajaQB.forEach(variedad => {
        indexCaja = productoSeleccionado.variedades.indexOf(variedad);
      });
      return indexCaja;
    }
    // si tiene hb selecciona el precio del bunche hb
    if (resultadoCajaHB.length > 0) {
      resultadoCajaHB.forEach(variedad => {
        indexCaja = productoSeleccionado.variedades.indexOf(variedad);
      });
      return indexCaja;
    }
    // si tiene eb selecciona el precio del bunche eb
    if (resultadoCajaEB.length > 0) {
      resultadoCajaEB.forEach(variedad => {
        indexCaja = productoSeleccionado.variedades.indexOf(variedad);
      });
      return indexCaja;
    }
  }

  tallaProducto(talla: string, producto: Caja, cajaSeleccionada: number, cambioFiltro: string) {
    var indexVariedad = 0;
    const index: number = this.productos.indexOf(producto);
    const productoSeleccionado = this.productos[index];
    for (var x = 0; x < productoSeleccionado.variedades.length; x++) {
      const variedad = productoSeleccionado.variedades[x];

      variedad.cajasCantidad = [];
      if (variedad.cantidadPorCaja > 0) {
        var variedadABuscar = productoSeleccionado.variedades.filter(x => x.talla == talla && x.cantidadPorCaja > 0);

        variedadABuscar.forEach(item => {

          item.cajasPorVariedad.sort((a, b) => a.valor - b.valor);

          const indexMinima = item.cajasPorVariedad.findIndex(x => x.caja === item.cajaMinima)

          const cajasDisponibles = item.cajasPorVariedad.slice(indexMinima)

          const cajaSeAgrega = cajasDisponibles.find(x => x.caja == item.caja)

          if (item.cantidadPorCaja > 0 && cajaSeAgrega != undefined) {
            variedad.cajasCantidad.push(item.cantidadPorCaja);
          }

        });

        if (cambioFiltro == "S") {
          for (var caja of variedad.cajasCantidad) {
            if (variedad.cantidadPorCaja == caja && variedad.talla == talla) {
              cajaSeleccionada = caja;
            } else if (producto.cajaSeleccionada == caja && producto.tallaSeleccionada == talla) {
              cajaSeleccionada = caja;
              break;
            }
          }
          if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
            var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
            variedad.cajasCantidad.splice(indexCaja, 1)
            variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
            variedad.cajasCantidad.sort((a, b) => a - b)
            indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
            break;
          }
        } else if (variedad.producto == "ROSES" || variedad.producto == "GARDEN ROSES"
          || variedad.producto == "MAYRAS GARDEN ROSES" || variedad.producto == "SPRAY ROSES") {
          if (productoSeleccionado.tallasFinales.filter(x => x.valor == "50 CM").length > 0) {
            if (variedad.cantidadPorCaja > 0) {
              if (variedad.talla == "50 CM" && variedad.cantidadPorCaja == cajaSeleccionada) {
                var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
                variedad.cajasCantidad.splice(indexCaja, 1)
                variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
                variedad.cajasCantidad.sort((a, b) => a - b)
                indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
                break;
              }
            }
          } else if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
            var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
            variedad.cajasCantidad.splice(indexCaja, 1)
            variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
            variedad.cajasCantidad.sort((a, b) => a - b)
            indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
            break;
          }
        } else if (variedad.producto == 'HYDRANGEA') {
          if (productoSeleccionado.tallasFinales.filter(x => x.valor == 'SUPER SELECT').length > 0) {
            if (variedad.talla == 'SUPER SELECT' && variedad.cantidadPorCaja == cajaSeleccionada) {
              var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
              variedad.cajasCantidad.splice(indexCaja, 1);
              variedad.cajasCantidad.splice(0, 0, cajaSeleccionada);
              variedad.cajasCantidad.sort((a, b) => a - b)
              indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
              break;
            }
          } else if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
            var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
            variedad.cajasCantidad.splice(indexCaja, 1)
            variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
            variedad.cajasCantidad.sort((a, b) => a - b)
            indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
            break;
          }
        } else if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
          var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
          variedad.cajasCantidad.splice(indexCaja, 1)
          variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
          variedad.cajasCantidad.sort((a, b) => a - b)
          indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
          break;
        }
      }
    }
    return indexVariedad;
  }

  _getDiaSemana(): string {
    this.dias = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.zonaHorariaEcuador = new Date().toLocaleString('en-US', { timeZone: 'America/Guayaquil' });
    this.horarioDeEcuador = new Date(this.zonaHorariaEcuador);
    this.horarioDeEcuador.setHours(this.horarioDeEcuador.getHours());
    this.horarioDeEcuador.setMinutes(this.horarioDeEcuador.getMinutes());
    this.diaSemana = this.dias[this.horarioDeEcuador.getDay()];
    return this.diaSemana;
  }

  _getHoraDia(): number {
    return this.horarioDeEcuador.getHours();
  }

  _getMinutos(): number {
    return this.horarioDeEcuador.getMinutes();
  }

  _getFechaFlorex() {
    var fechaInicia = new Date();
    var datePipe = new DatePipe("en-US");
    if (localStorage.getItem("_ls_dateConecction") != null || localStorage.getItem("_ls_dateConecction") != undefined) {
      this.date = new FormControl(new Date(localStorage.getItem('_ls_dateConecction')));
      this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
      localStorage.setItem("_ls_dateConecction", this.date.value);
      return;
    }
    if (this.usuario.codigoClientePadre == 1940) { //Si es FLOWERFULL
      if (this.diaSemana === 'Sunday' ||
        this.diaSemana === 'Monday' ||
        this.diaSemana === 'Tuesday') {
        fechaInicia.setDate(fechaInicia.getDate() + 4);
      }
      if (this.diaSemana === 'Wednesday' ||
        this.diaSemana === 'Thursday' ||
        this.diaSemana === 'Friday' ||
        this.diaSemana === 'Saturday') {
        fechaInicia.setDate(fechaInicia.getDate() + 5);
      }
    }
    else { //Si no es FLOWERFULL
      if (this.diaSemana === 'Monday' ||
        this.diaSemana === 'Tuesday' ||
        this.diaSemana === 'Wednesday' ||
        this.diaSemana === 'Thursday' ||
        this.diaSemana === 'Friday') {
        fechaInicia.setDate(fechaInicia.getDate() + 3);
      }
      if (this.diaSemana === 'Saturday') {
        fechaInicia.setDate(fechaInicia.getDate() + 5);
      }
      if (this.diaSemana === 'Sunday') {
        fechaInicia.setDate(fechaInicia.getDate() + 4);
      }
    }

    this.date = new FormControl(new Date(fechaInicia));
    this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
    localStorage.setItem("_ls_dateConecction", this.date.value);
  }

  _calcularDiasEntregaPedido(dia: string) {
    var fechaInicia = new Date();
    var datePipe = new DatePipe("en-US");
    if (localStorage.getItem("_ls_dateConecction") != null || localStorage.getItem("_ls_dateConecction") != undefined) {
      this.date = new FormControl(new Date(localStorage.getItem('_ls_dateConecction')));
      this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
      localStorage.setItem("_ls_dateConecction", this.date.value);
      return;
    }
    if (this.usuario.codigoClientePadre == 1940) { //Si es FLOWERFULL
      if (dia === 'Saturday' || dia === 'Sunday' || dia === 'Monday') {
        fechaInicia.setDate(fechaInicia.getDate() + 5);
      }
      if (dia === 'Tuesday' || dia === 'Wednesday' || dia === 'Thursday' || dia === 'Friday') {
        fechaInicia.setDate(fechaInicia.getDate() + 6);
      }
    }
    else { //Si no es FLOWERFULL
      if (dia === 'Monday' || dia === 'Tuesday' || dia === 'Wednesday' || dia === 'Thursday') {
        fechaInicia.setDate(fechaInicia.getDate() + 4);
      }
      if (dia === 'Friday') {
        fechaInicia.setDate(fechaInicia.getDate() + 6);
      }
    }

    this.date = new FormControl(new Date(fechaInicia));
    this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
    localStorage.setItem("_ls_dateConecction", this.date.value);
  }

  _getCalcularDiaEntrega() {
    var dia = this._getDiaSemana();
    var hora = this._getHoraDia();
    var minutos = this._getMinutos();

    switch (dia) {
      case 'Monday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Monday');
        }
        break;
      }
      case 'Tuesday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Tuesday');
        }
        break;
      }
      case 'Wednesday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Wednesday');
        }
        break;
      }
      case 'Thursday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Thursday');
        }
        break;
      }
      case 'Friday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Friday');
        }
        break;
      }
      case 'Saturday': {
        this._getFechaFlorex();
        break;
      }
      case 'Sunday': {
        this._getFechaFlorex();
        break;
      }
    }
  }

  public incrementVarios(variedad) {
    // let variedadBuscar = this.producto.variedades[this.indexVariedad];
    this.variedad = variedad;

    let stading: boolean;
    if (this.paginaRuta === '/stading') { stading = true } else { stading = false }
    var respuesta = this.appService.agregarCajaCart(this.variedad, this.imagen, this.cantidadCajas, this.producto, stading, this.producto.stadingOrder);

    if (respuesta) {
      const timeout = 1500;
      const dialogRef = this.dialog.open(AddedComponent, {
        data: { producto: this.variedad, tipoAgrega: 'C', imagen: this.imagen, cantidad: this.cantidadCajas },
        width: '400px',
        panelClass: 'added-product'
      });
      dialogRef.afterClosed().subscribe(res => { });
      /*dialogRef.afterOpened().subscribe(res => {
        setTimeout(() => {
          dialogRef.close();
        }, timeout)
      });*/
    }

    this.limpiarOut.emit("1");

  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

}
