import { AppComponent } from './../../app.component';
import { Variedad, Caja, Cajas, Marcacion, Destino, Colores, Filtro, PaginadorProducto } from 'src/app/app.modelsWebShop';
import { FincaPreferida } from "src/app/FincaPreferida";
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AppService } from '../../app.service';
import { ClienteDTO } from 'src/app/app.models';
import { NoteBoxesComponent } from 'src/app/pages/popus/note-boxes/note-boxes.component';
import { EnumPagina, EnumSiNo, EnumTipoCaja } from 'src/app/enumeration/enumeration';
import { DeleteComponent } from 'src/app/pages/popus/delete/delete.component';
import { InformationComponent } from 'src/app/pages/popus/information/information.component';
import { DatePipe } from '@angular/common';
import { SharedService } from '../service/shared.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IncreaseboxComponent } from 'src/app/pages/popus/increasebox/increasebox.component';


@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() producto: Caja;
  @Input() indexVariedad: number;
  @Input() type: string;
  @Input() imagen: any
  @Input() totalCajaSeleccionada: number;
  @Input() cajaSeleccionada: string;
  @Input() cantidadBunches: number;
  @Input() variedad: Variedad
  @Input() variedadCajaSeleccionadaNumber: number
  @Output() cajaSeleccionadaActualizada: EventEmitter<string> = new EventEmitter<string>();
  @Output('parametro') numero: EventEmitter<number> = new EventEmitter();
  @Input() TipoCaja: string;
  @Input() paginaRuta: string;
  @Output() DatosProductosOu: EventEmitter<any> = new EventEmitter<any>();
  @Input() DatosProductosIu: []
  @Input() typeBoxCountOutput: any
  @Input() tallasVariedadProducto = [];
  @Input() cajasVariedadProducto = [];
  @Input() cantidadBoucheProducto: number;
  @Input() listaProveedorProducto: string;
  @Input() sePuedeMezclar: string;
  @Input() marcacionSleccionada: Marcacion;
  @Input() camionSeleccionado;
  @Input() tiposCajaProducto: Cajas[] = [];
  @Output() cajaArmadaOuput: EventEmitter<Caja> = new EventEmitter<Caja>();
  @Output() agregarACaja: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitirPopupCamion: EventEmitter<any> = new EventEmitter<any>();
  dateNowInit: string;
  destinoSeleccionado: Destino;
  zonaHorariaEcuador;
  horarioDeEcuador = new Date();
  dateNews = new Date();
  productosPaginados: Array<Caja> = [];
  date: any;
  diaSemana = '';

  coloresWebShop: Colores[] = [];
  coloresEncontrados: number = 0;
  datePipe;
  totalRegistros = 0;
  listaFiltros: Filtro[] = [];
  listaFiltrosFinales: Array<Filtro> = [];
  dias = [];

  paginado = new PaginadorProducto();

  isLoading = false

  isLoadingOne = false

  usuario: any;

  public align = 'center center';
  public DatosProductos: Array<Caja> = [];
  public productos: Array<Caja> = [];
  public resultadoProveedores: FincaPreferida[] = [];

  private subscription: Subscription;

  constructor(public appService: AppService, public snackBar: MatSnackBar,
    public dialog: MatDialog, private sharedService: SharedService) {
      this.subscription = this.appService.eventoSeleccionaCamionYPo.subscribe(() => {
        this.agregarVariosBunches()
      })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
  }

  ajustarTamanio() {
    if (this.variedad.cajaMinima == 'QB' && this.appService.cajaSeleccionada == 'EB') {
      return 'QB';
    }
    if (this.variedad.cajaMinima == 'HB' && this.appService.cajaSeleccionada == 'QB') {
      return 'HB';
    }
    if (this.variedad.cajaMinima == 'EB' && this.appService.cajaSeleccionada == 'EB') {
      return 'EB';
    }
    if (this.variedad.cajaMinima == undefined || this.variedad.cajaMinima == null) {
      return this.appService.cajaSeleccionada;
    }
    return this.appService.cajaSeleccionada
  }

  public agregarVariosBunches() {

    if (sessionStorage.getItem('Camion') == null
      || sessionStorage.getItem('Camion') == 'undefined'
      || sessionStorage.getItem('Destino') == null
      || sessionStorage.getItem('Destino') == 'undefined'
      || sessionStorage.getItem('Marcacion') == null
      || sessionStorage.getItem('Marcacion') == 'undefined'
    ) {

      console.log('Emitir popups');
      const variablesPorAgregar = []
      variablesPorAgregar.push(this.producto.tallaSeleccionada)
      variablesPorAgregar.push(this.cantidadBunches)
      variablesPorAgregar.push(this.indexVariedad)
      variablesPorAgregar.push(this.producto.variedades[this.indexVariedad])
      variablesPorAgregar.push(this.producto)
      this.sharedService.respaldarDatosProductoAgregar(variablesPorAgregar)

      this.emitirPopupCamion.emit(null);

      return;
    }

    if (this.sharedService.productoRespaldo != null) {
      const cantidadBunchesRespaldo = this.sharedService.productoRespaldo[1];
      const variedadRespaldo = this.sharedService.productoRespaldo[3];
      const productoRespaldo = this.sharedService.productoRespaldo[4];
      this.cantidadBunches = cantidadBunchesRespaldo
      this.producto = productoRespaldo
      this.imagen = this.producto.imagen
      this.variedad = this.actualizarPrecioVariedad(variedadRespaldo);
      this.sharedService.respaldarDatosProductoAgregar(null)
    }else{
      this.producto.tallaSeleccionada = this.producto.tallaSeleccionada.replace("/", '');
      this.appService.resultadoBusqueda = false;
      this.variedad = null;
      if (this.cantidadBunches == null || this.cantidadBunches == undefined) { return }
      this.variedad = this.producto.variedades[this.indexVariedad];
    }

    console.log("Antes de ajustar: Variedad", this.variedad);
    this.appService.cajaSeleccionada = this.ajustarTamanio();
    console.log("despues de ajustar: cajaSeleccionada", this.appService.cajaSeleccionada);
    //Para queryRoses
    this.appService.validarSoloRoses = this.producto.variedades[0].producto
    //var resultado = this.obtenerTipoCaja(this.producto.variedades[this.indexVariedad].cajasPorVariedad)[0];
    //this.appService.cajaSeleccionadaNumber = resultado.valor;
    //this.appService.cajaSeleccionada = resultado.caja;
    this._agregarBunchesPorCaja();

  }

  public actualizarPrecioVariedad(variedad) {
    this.destinoSeleccionado = JSON.parse(sessionStorage.getItem('Destino'));
    const c: any = JSON.parse(localStorage.getItem('Usuario'));

    for (let z = 0; z < variedad.precios.length; z++) {
      const precio = variedad.precios[z];
      let totalAux = 0;
      let totalAuxJv = 0;

      if (precio.tipoPrecio === EnumSiNo.N) {

        let porcentajeSumar = 0;

        if (c.codigoClientePadre !== undefined) {
          porcentajeSumar = c.porcentajeSubcliente / 100;
        } else if (this.destinoSeleccionado !== null && this.destinoSeleccionado !== undefined
          && this.destinoSeleccionado.subcliente !== null
          && this.destinoSeleccionado.subcliente !== undefined
          && this.destinoSeleccionado.subcliente.marginSubcliente !== null
          && this.destinoSeleccionado.subcliente.marginSubcliente !== undefined) {
          porcentajeSumar = this.destinoSeleccionado.subcliente.marginSubcliente / 100;
        }

        const sumarPrecio = precio.precio * porcentajeSumar;
        const sumarPrecioJv = precio.precioJv * porcentajeSumar;

        precio.precio += sumarPrecio;
        precio.precioJv += sumarPrecioJv;

        if (precio.codigoTipoCliente === c.codigoTipoCliente) {
          variedad.precio = precio.precio;
          variedad.precioCliente = precio.precioCliente;
          variedad.precioJv = precio.precioJv;

          // if (element.combo === EnumSiNo.S) {
          //   variedad.cajaCombo = EnumSiNo.S;
          //   totalAux = variedad.precio * variedad.cantidadPorCaja;
          //   totalAuxJv = variedad.precioJv * variedad.cantidadPorCaja;
          //   element.totalPrecio += totalAux;
          //   element.totalPrecioJv += totalAuxJv;
          // }
        }
      }
    }
    return variedad
  }

  public actualizarDatos() {
    this.camionSeleccionado = JSON.parse(sessionStorage.getItem('Camion'));
    this.destinoSeleccionado = JSON.parse(sessionStorage.getItem("Destino"));
    this.marcacionSleccionada = JSON.parse(sessionStorage.getItem("Marcacion"));
    this._getCalcularDiaEntrega()
  }

  getColoresWebShop(numero: number) {
    this.appService.getColoresWebShop().subscribe(colores => {
      this.coloresEncontrados = colores.numRegistros
      this.coloresWebShop = JSON.parse(colores.json);
      this.coloresWebShop.forEach(x => {
        x.nombre = x.nombre.toLowerCase();
        x.estilo = 'c-' + x.nombre.toLocaleLowerCase().replace(' ', '-');
        x.select = EnumSiNo.N
      })
      this.coloresWebShop = this.coloresWebShop.sort(function (a, b) {
        return a.nombre.localeCompare(b.nombre);
      });
    });
  }

  async obtenerProductosPorTemporada() {

    this.getColoresWebShop(0);

    let dateConnection = localStorage.getItem('_ls_dateConecction');

    if (dateConnection != null || dateConnection != undefined) {
      this.date = new Date(dateConnection);

      this.datePipe = new DatePipe("en-US");
      this.paginado.fecha = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    }

    await this.getproductosWebShop();
  }

  getproductosWebShop() {
    this.isLoadingOne = true
    this.isLoading = true;
    this.appService.getProductosWebShopPost(this.paginado).subscribe(data => {

      this.totalRegistros = data.numRegistros;

      const c: any = JSON.parse(localStorage.getItem('Usuario'));
      const cajas: Caja[] = JSON.parse(data.json);
      this.productos = cajas.filter(x => x.combo == EnumSiNo.N);

      if (this.productos.length > 0) {

        this.appService.orderWebShop = c.orderWebShop === EnumSiNo.N ? EnumSiNo.N : EnumSiNo.S;
        for (let index = 0; index < this.productos.length; index++) {

          const element = this.productos[index];
          element.tallasDeCaja = [];
          element.tallasCajaCm = [];
          element.tallasFinales = [];
          element.cajasDisponiblesMixtas = [];
          element.imagen = this.appService.urlImagen + element.imagenes[0];
          element.stadingOrder = false;
          element.nombreProducto = element.variedades[0].nombreVariedad.toLowerCase() + " - " + element.variedades[0].producto.toLowerCase();
          for (let x = 0; x < element.variedades.length; x++) {
            const variedad = element.variedades[x];
            variedad.cajasPorVariedad = [];
            var buscarVariedadesPorTalla = element.variedades.filter(x => x.talla == variedad.talla);
            if (buscarVariedadesPorTalla.length > 0) {
              buscarVariedadesPorTalla.forEach(caja => {
                variedad.cajasPorVariedad.push({
                  caja: caja.caja,
                  valor: caja.cantidadPorCajaMixta
                })
              });
              var hash = {};
              variedad.cajasPorVariedad = variedad.cajasPorVariedad.filter(function (productoCaja) {
                var exists = !hash[productoCaja.caja];
                hash[productoCaja.caja] = true;
                return exists;
              });
            }
            element.cajasDisponiblesMixtas.push(variedad.cantidadPorCajaMixta);
            if (variedad.cantidadPorCaja > 0) {
              element.tallasDeCaja.push(variedad.talla);
              element.tallasCajaCm.push(variedad.tallaCm);
              element.tallasFinales.push({ codigo: variedad.tallaCm, valor: variedad.talla });
              if (element.combo == EnumSiNo.S) { element.totalPiezas += variedad.cantidadPorCaja; }
              variedad.stadingOrder = false;
              variedad.cajaCombo = EnumSiNo.N;
              variedad.disabledBox = false;
              variedad.disabledBunches = false;
              variedad.disabled = variedad.seguridad == "si" ? true : false;
              element.botonBox = EnumSiNo.S;
              element.botonBunches = variedad.mostrarPrecioPorCaja == 'no' ? EnumSiNo.N : EnumSiNo.S;
              for (let z = 0; z < variedad.precios.length; z++) {
                var precio = variedad.precios[z];
                var totalAux = 0;
                var totalAuxJv = 0;
                if (precio.tipoPrecio == EnumSiNo.N) {
                  if (c.codigoClientePadre != undefined) {
                    var porcentajeSumar = c.porcentajeSubcliente / 100;
                    var sumarPrecio = precio.precio * porcentajeSumar
                    var sumarPrecioJv = precio.precioJv * porcentajeSumar
                    precio.precio += sumarPrecio
                    precio.precioJv += sumarPrecioJv
                  }
                  if (c.codigoClientePadre === undefined && this.destinoSeleccionado != null) {
                    if (this.destinoSeleccionado.subcliente != null) {
                      if (this.destinoSeleccionado.subcliente.marginSubcliente != null && this.destinoSeleccionado.subcliente.marginSubcliente > 0) {
                        var porcentajeSumar = this.destinoSeleccionado.subcliente.marginSubcliente / 100;
                        var sumarPrecio = precio.precio * porcentajeSumar
                        var sumarPrecioJv = precio.precioJv * porcentajeSumar
                        precio.precio += sumarPrecio
                        precio.precioJv += sumarPrecioJv
                      }
                    }
                  }
                  if (precio.codigoTipoCliente === c.codigoTipoCliente) {
                    variedad.precio = precio.precio;
                    variedad.precioCliente = precio.precioCliente
                    variedad.precioJv = precio.precioJv
                    if (element.combo == EnumSiNo.S) {
                      variedad.cajaCombo = EnumSiNo.S
                      totalAux = variedad.precio * variedad.cantidadPorCaja
                      totalAuxJv = variedad.precioJv * variedad.cantidadPorCaja
                      element.totalPrecio += totalAux
                      element.totalPrecioJv += totalAuxJv
                    }
                  }
                }
              }
            }
          }
          var tallaOriginalMenor = element.tallasCajaCm[0];
          var cajaOriginalMenor = [];
          var tallasUnicas = Array.from(new Set(element.tallasCajaCm));
          var tallaStr = element.tallasDeCaja[0];
          var hash = {};
          element.tallasFinales = element.tallasFinales.filter(function (current) {
            var exists = !hash[current.valor];
            hash[current.valor] = true;
            return exists;
          });
          element.tallasCajaCm = tallasUnicas.sort((a, b) => a - b);
          element.tallasFinales.sort((a, b) => a.codigo + b.codigo);
          element.tallaSeleccionada = tallaStr;// + '/';
          if (element.variedades[0].producto == "ROSES" || element.variedades[0].producto == "GARDEN ROSES"
            || element.variedades[0].producto == "MAYRAS GARDEN ROSES" || element.variedades[0].producto == "SPRAY ROSES") {
            if (element.tallasFinales.filter(x => x.valor === "50 CM").length > 0) {
              tallaOriginalMenor = 50;
              tallaStr = "50 CM";
              element.tallasDeCaja = [];
              element.tallasDeCaja.push(tallaStr);
              element.tallaSeleccionada = tallaStr;// + '/';
            }
          }
          if (element.variedades[0].producto == "HYDRANGEA") {
            if (element.tallasFinales.filter(x => x.valor === 'SUPER SELECT').length > 0) {
              tallaOriginalMenor = 0;
              tallaStr = 'SUPER SELECT';
              element.tallasDeCaja = [];
              element.tallasDeCaja.push(tallaStr);
              element.tallaSeleccionada = tallaStr;// + '/';
            }
          }
          var variedadBusqueda = [];
          element.variedades.forEach(variedad => {
            if (variedad.cantidadPorCaja > 0) {
              if (variedad.tallaCm == tallaOriginalMenor && variedad.talla == tallaStr) {
                variedadBusqueda.push(variedad)
              }
            }
          });
          variedadBusqueda.forEach(item => {
            cajaOriginalMenor.push(item.cantidadPorCaja);
          });
          //debugger
          var minCaja = Math.max(...cajaOriginalMenor);
          element.cajasDisponiblesMixtas = Array.from(new Set(element.cajasDisponiblesMixtas));
          element.cajaSeleccionada = minCaja;
          element.indexVariedadSeleccionada = this.tallaProducto(tallaStr, element, minCaja, "N");
          element.indexPorTipoCaja = this.actualizarIndexPorTipoCaja(element, tallaStr);
          element.tallasFinales.sort((a, b) => a.codigo - b.codigo);

        }
        //function comparar(a, b) { return a - b; };
        this.listaFiltros = [];
        this.listaFiltrosFinales = [];
        for (let item of cajas) {
          this.listaFiltros.push({ tipo: item.variedades[0].nombreVariedad.toLowerCase(), valor: item.variedades[0].producto.toLowerCase(), seleccionado: 'N' })
          this.listaFiltrosFinales.push({ tipo: item.variedades[0].nombreVariedad, valor: item.variedades[0].producto, seleccionado: null })
        }
        // this._getProductosFiltro();
        // this._filterOptions();
        // this._getCategoriasAutocomplete();
        // this._obtenerColoresFiltro();
        // this._getColoresAutocomplete();
        //this.getColoresWebShop(5);`
        this.productosPaginados = this.productosPaginados.concat(this.productos);
      }
      this.isLoading = false;
      this.isLoadingOne = false;
    });
  }

  actualizarIndexPorTipoCaja(producto, talla: string): number {
    var indexCaja: number;
    const index: number = this.productos.indexOf(producto);
    const productoSeleccionado = this.productos[index];
    var resultadoCajaEB = productoSeleccionado.variedades.filter(x => x.caja === "EB" && x.talla === talla && x.cantidadPorCaja > 0)
    var resultadoCajaQB = productoSeleccionado.variedades.filter(x => x.caja === "QB" && x.talla === talla && x.cantidadPorCaja > 0)
    var resultadoCajaHB = productoSeleccionado.variedades.filter(x => x.caja === "HB" && x.talla === talla && x.cantidadPorCaja > 0)
    // si tiene qb selecciona el precio del bunche qb
    if (resultadoCajaQB.length > 0) {
      resultadoCajaQB.forEach(variedad => {
        indexCaja = productoSeleccionado.variedades.indexOf(variedad);
      });
      return indexCaja;
    }
    // si tiene hb selecciona el precio del bunche hb
    if (resultadoCajaHB.length > 0) {
      resultadoCajaHB.forEach(variedad => {
        indexCaja = productoSeleccionado.variedades.indexOf(variedad);
      });
      return indexCaja;
    }
    // si tiene eb selecciona el precio del bunche eb
    if (resultadoCajaEB.length > 0) {
      resultadoCajaEB.forEach(variedad => {
        indexCaja = productoSeleccionado.variedades.indexOf(variedad);
      });
      return indexCaja;
    }
  }

  tallaProducto(talla: string, producto: Caja, cajaSeleccionada: number, cambioFiltro: string) {
    var indexVariedad = 0;
    const index: number = this.productos.indexOf(producto);
    const productoSeleccionado = this.productos[index];
    for (var x = 0; x < productoSeleccionado.variedades.length; x++) {
      const variedad = productoSeleccionado.variedades[x];

      variedad.cajasCantidad = [];
      if (variedad.cantidadPorCaja > 0) {
        var variedadABuscar = productoSeleccionado.variedades.filter(x => x.talla == talla && x.cantidadPorCaja > 0);

        variedadABuscar.forEach(item => {

          item.cajasPorVariedad.sort((a, b) => a.valor - b.valor);

          const indexMinima = item.cajasPorVariedad.findIndex(x => x.caja === item.cajaMinima)

          const cajasDisponibles = item.cajasPorVariedad.slice(indexMinima)

          const cajaSeAgrega = cajasDisponibles.find(x => x.caja == item.caja)

          if (item.cantidadPorCaja > 0 && cajaSeAgrega != undefined) {
            variedad.cajasCantidad.push(item.cantidadPorCaja);
          }

        });

        if (cambioFiltro == "S") {
          for (var caja of variedad.cajasCantidad) {
            if (variedad.cantidadPorCaja == caja && variedad.talla == talla) {
              cajaSeleccionada = caja;
            } else if (producto.cajaSeleccionada == caja && producto.tallaSeleccionada == talla) {
              cajaSeleccionada = caja;
              break;
            }
          }
          if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
            var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
            variedad.cajasCantidad.splice(indexCaja, 1)
            variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
            variedad.cajasCantidad.sort((a, b) => a - b)
            indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
            break;
          }
        } else if (variedad.producto == "ROSES" || variedad.producto == "GARDEN ROSES"
          || variedad.producto == "MAYRAS GARDEN ROSES" || variedad.producto == "SPRAY ROSES") {
          if (productoSeleccionado.tallasFinales.filter(x => x.valor == "50 CM").length > 0) {
            if (variedad.cantidadPorCaja > 0) {
              if (variedad.talla == "50 CM" && variedad.cantidadPorCaja == cajaSeleccionada) {
                var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
                variedad.cajasCantidad.splice(indexCaja, 1)
                variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
                variedad.cajasCantidad.sort((a, b) => a - b)
                indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
                break;
              }
            }
          } else if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
            var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
            variedad.cajasCantidad.splice(indexCaja, 1)
            variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
            variedad.cajasCantidad.sort((a, b) => a - b)
            indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
            break;
          }
        } else if (variedad.producto == 'HYDRANGEA') {
          if (productoSeleccionado.tallasFinales.filter(x => x.valor == 'SUPER SELECT').length > 0) {
            if (variedad.talla == 'SUPER SELECT' && variedad.cantidadPorCaja == cajaSeleccionada) {
              var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
              variedad.cajasCantidad.splice(indexCaja, 1);
              variedad.cajasCantidad.splice(0, 0, cajaSeleccionada);
              variedad.cajasCantidad.sort((a, b) => a - b)
              indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
              break;
            }
          } else if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
            var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
            variedad.cajasCantidad.splice(indexCaja, 1)
            variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
            variedad.cajasCantidad.sort((a, b) => a - b)
            indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
            break;
          }
        } else if (variedad.talla == talla && variedad.cantidadPorCaja == cajaSeleccionada) {
          var indexCaja = variedad.cajasCantidad.indexOf(variedad.cantidadPorCaja)
          variedad.cajasCantidad.splice(indexCaja, 1)
          variedad.cajasCantidad.splice(0, 0, cajaSeleccionada)
          variedad.cajasCantidad.sort((a, b) => a - b)
          indexVariedad = productoSeleccionado.variedades.indexOf(variedad);
          break;
        }
      }
    }
    return indexVariedad;
  }

  _getDiaSemana(): string {
    this.dias = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.zonaHorariaEcuador = new Date().toLocaleString('en-US', { timeZone: 'America/Guayaquil' });
    this.horarioDeEcuador = new Date(this.zonaHorariaEcuador);
    this.horarioDeEcuador.setHours(this.horarioDeEcuador.getHours());
    this.horarioDeEcuador.setMinutes(this.horarioDeEcuador.getMinutes());
    this.diaSemana = this.dias[this.horarioDeEcuador.getDay()];
    return this.diaSemana;
  }

  _getHoraDia(): number {
    return this.horarioDeEcuador.getHours();
  }

  _getMinutos(): number {
    return this.horarioDeEcuador.getMinutes();
  }

  _getFechaFlorex() {
    var fechaInicia = new Date();
    var datePipe = new DatePipe("en-US");
    if (localStorage.getItem("_ls_dateConecction") != null || localStorage.getItem("_ls_dateConecction") != undefined) {
      this.date = new FormControl(new Date(localStorage.getItem('_ls_dateConecction')));
      this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
      localStorage.setItem("_ls_dateConecction", this.date.value);
      return;
    }
    if (this.usuario.codigoClientePadre == 1940) { //Si es FLOWERFULL
      if (this.diaSemana === 'Sunday' ||
        this.diaSemana === 'Monday' ||
        this.diaSemana === 'Tuesday') {
        fechaInicia.setDate(fechaInicia.getDate() + 4);
      }
      if (this.diaSemana === 'Wednesday' ||
        this.diaSemana === 'Thursday' ||
        this.diaSemana === 'Friday' ||
        this.diaSemana === 'Saturday') {
        fechaInicia.setDate(fechaInicia.getDate() + 5);
      }
    }
    else { //Si no es FLOWERFULL
      if (this.diaSemana === 'Monday' ||
        this.diaSemana === 'Tuesday' ||
        this.diaSemana === 'Wednesday' ||
        this.diaSemana === 'Thursday' ||
        this.diaSemana === 'Friday') {
        fechaInicia.setDate(fechaInicia.getDate() + 3);
      }
      if (this.diaSemana === 'Saturday') {
        fechaInicia.setDate(fechaInicia.getDate() + 5);
      }
      if (this.diaSemana === 'Sunday') {
        fechaInicia.setDate(fechaInicia.getDate() + 4);
      }
    }

    this.date = new FormControl(new Date(fechaInicia));
    this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
    localStorage.setItem("_ls_dateConecction", this.date.value);
  }

  _calcularDiasEntregaPedido(dia: string) {
    var fechaInicia = new Date();
    var datePipe = new DatePipe("en-US");
    if (localStorage.getItem("_ls_dateConecction") != null || localStorage.getItem("_ls_dateConecction") != undefined) {
      this.date = new FormControl(new Date(localStorage.getItem('_ls_dateConecction')));
      this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
      localStorage.setItem("_ls_dateConecction", this.date.value);
      return;
    }
    if (this.usuario.codigoClientePadre == 1940) { //Si es FLOWERFULL
      if (dia === 'Saturday' || dia === 'Sunday' || dia === 'Monday') {
        fechaInicia.setDate(fechaInicia.getDate() + 5);
      }
      if (dia === 'Tuesday' || dia === 'Wednesday' || dia === 'Thursday' || dia === 'Friday') {
        fechaInicia.setDate(fechaInicia.getDate() + 6);
      }
    }
    else { //Si no es FLOWERFULL
      if (dia === 'Monday' || dia === 'Tuesday' || dia === 'Wednesday' || dia === 'Thursday') {
        fechaInicia.setDate(fechaInicia.getDate() + 4);
      }
      if (dia === 'Friday') {
        fechaInicia.setDate(fechaInicia.getDate() + 6);
      }
    }

    this.date = new FormControl(new Date(fechaInicia));
    this.dateNowInit = datePipe.transform(this.date.value, 'yyyy-MM-dd');
    localStorage.setItem("_ls_dateConecction", this.date.value);
  }

  _getCalcularDiaEntrega() {
    var dia = this._getDiaSemana();
    var hora = this._getHoraDia();
    var minutos = this._getMinutos();

    switch (dia) {
      case 'Monday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Monday');
        }
        break;
      }
      case 'Tuesday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Tuesday');
        }
        break;
      }
      case 'Wednesday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Wednesday');
        }
        break;
      }
      case 'Thursday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Thursday');
        }
        break;
      }
      case 'Friday': {
        if (hora < 10 && minutos <= 59) {
          this._getFechaFlorex();
        }
        if (hora >= 10 && minutos >= 0) {
          this._calcularDiasEntregaPedido('Friday');
        }
        break;
      }
      case 'Saturday': {
        this._getFechaFlorex();
        break;
      }
      case 'Sunday': {
        this._getFechaFlorex();
        break;
      }
    }
  }

  obtenerTipoCaja(cajaPorVariedad: Cajas[]): Cajas[] {
    var resultadoCaja: Cajas[] = [];
    debugger
    const existeCaja = cajaPorVariedad.some(item => item.caja === this.appService.cajaSeleccionada);

    if (!existeCaja) {
      this.appService.cajaSeleccionada = cajaPorVariedad[0].caja
    }

    switch (this.appService.cajaSeleccionada) {
      case 'EB': {
        if (cajaPorVariedad.filter(x => x.caja === EnumTipoCaja.EB).length > 0) {
          resultadoCaja = cajaPorVariedad.filter(x => x.caja === EnumTipoCaja.EB)
        }
        return resultadoCaja;
      }
      case 'QB': {
        if (cajaPorVariedad.filter(x => x.caja === EnumTipoCaja.QB).length > 0) {
          resultadoCaja = cajaPorVariedad.filter(x => x.caja === EnumTipoCaja.QB)
        }
        return resultadoCaja;
      }
      case 'HB': {
        if (cajaPorVariedad.filter(x => x.caja === EnumTipoCaja.HB).length > 0) {
          resultadoCaja = cajaPorVariedad.filter(x => x.caja === EnumTipoCaja.HB)
        }
        return resultadoCaja;
      }
    }
  }

  public _agregarBunchesPorCaja() {
    var contador = 0;
    var agregado;
    var contagregados = 0;
    for (var index = 0; index < this.cantidadBunches; index++) {
      contador++;
     // var caja = this.tiposCajaProducto.filter(x => x.caja === this.appService.cajaSeleccionada)[0].valor;
      var cajaFull = this.appService.calularPorcentajeAntesDeAgregar(this.appService.cajaSeleccionada, this.variedad, this.cantidadBunches);
      if (cajaFull > 100 && this.appService.cajaSeleccionada === EnumTipoCaja.HB) {
        this.appService.getImagenBarraProgreso().subscribe(data => {
          this.appService._validarCajaSeleccionada(this.appService.cajaSeleccionada, data);
        });
        this._mensajeCajaCompleta();
        agregado = false;
        break;
      }
      if (this.appService.CajaArmada.totalProcentajeLleno < 100) {
        // var resultado = this.obtenerTipoCaja(this.producto.variedades[this.indexVariedad].cajasPorVariedad)[0];
        // if(resultado != undefined){
        //   this.appService.cajaSeleccionada = resultado.caja;
        // }
        //this.appService.cajaSeleccionadaNumber = this.appService.totalCaja(this.appService.cajaSeleccionada, resultado.valor);
       // this.appService.cajaSeleccionadaNumber = this.tiposCajaProducto.filter(x => x.caja === this.appService.cajaSeleccionada)[0].valor;
        agregado = this.appService.agregarACaja(
          this.variedad,
          this.appService.cajaSeleccionada,
          this.producto.imagen,
          this.producto.tallasFinales,
          [this.producto],
          this.tiposCajaProducto,
          this.cajasVariedadProducto,
          this.cantidadBunches //20 //   this.appService.cajaSeleccionadaNumber
        );
        if (agregado == false) {
          this._mensajeAgrandarCaja(contador);
          break;
        } else {
          contagregados++;
        }
      } else {
        this._mensajeAgrandarCaja(contador);
        break;
      }
    }
    // if (agregado) {
    if (contagregados > 0) {
      this.appService._barraProgresoPorcentaje(this.variedad);
      this.filtrarProductos();
      this.appService.CajaArmada.totalProcentajeLleno = Math.round(this.appService.CajaArmada.totalProcentajeLleno);
    }

  }

  public _mensajeAgrandarCaja(contador: number) {
    if (this.tiposCajaProducto.filter(x => x.caja === EnumTipoCaja.HB).length == 0 && this.appService.cajaSeleccionada === EnumTipoCaja.QB) {
      return;
    }
    var respuesta = true; //eliminar si se quiere obtener mensaje de ENLARGE BOX y descomentar
    // const dialogRef = this.dialog.open(NoteBoxesComponent, {
    //   data: { variedad: null, condicion: 'ENLARGE' },
    //   panelClass: 'note-boxes'
    // });
    // dialogRef.afterClosed().subscribe(respuesta => {
    if (respuesta) {
      window.scrollTo(0, 100);
      if (this.appService.cajaSeleccionada != EnumTipoCaja.HB) {
        this.appService.cajaSeleccionada = this.appService.actualizarCajaSeleccionada(this.appService.cajaSeleccionada);
      }
    //  this.appService.cajaSeleccionadaNumber = this.tiposCajaProducto.filter(x => x.caja === this.appService.cajaSeleccionada)[0].valor;
      this.appService._calcularPorcentajeCajaArmada(this.appService.cajaSeleccionada);
      this.appService.CajaArmada.totalProcentajeLleno = Math.round(this.appService.CajaArmada.totalProcentajeLleno);
      for (let index = 0; index <= this.cantidadBunches - contador; index++) {
        if (this.appService.CajaArmada.totalProcentajeLleno >= 100 && this.appService.cajaSeleccionada == "HB") {
          this._mensajeCajaCompleta();
          break;
        }
        if (this.appService.CajaArmada.totalProcentajeLleno >= 100 && this.appService.cajaSeleccionada == "QB") {
          this.appService.cajaSeleccionada = this.appService.actualizarCajaSeleccionada(this.appService.cajaSeleccionada);
       //   this.appService.cajaSeleccionadaNumber = this.tiposCajaProducto.filter(x => x.caja === this.appService.cajaSeleccionada)[0].valor
          this.appService._calcularPorcentajeCajaArmada(this.appService.cajaSeleccionada);
          this.emitirCajaSelecionada(this.appService.cajaSeleccionada);
        }
        this.appService.agregarACaja(
          this.variedad,
          this.appService.cajaSeleccionada,
          this.producto.imagen,
          this.producto.tallasFinales,
          [this.producto],
          this.tiposCajaProducto,
          this.cajasVariedadProducto,
          this.cantidadBunches
          //20 //   this.appService.cajaSeleccionadaNumber
        );
      }
      this.appService.CajaArmada.totalProcentajeLleno = Math.round(this.appService.CajaArmada.totalProcentajeLleno);
    }
    // });
  }

  public _mensajeCajaCompleta() {
    const dialogRef = this.dialog.open(NoteBoxesComponent, {
      data: { variedad: null, condicion: 'FULL' },
      panelClass: 'note-boxes'
    });
  }

  public filtrarProductos() {
    if (this.listaProveedorProducto === "N") {
      this.cajaArmadaOuput.emit(this.appService.CajaArmada);
      this.appService._barraProgresoPorcentaje(this.variedad)
      return;
    }
    this.appService._barraProgresoPorcentaje(this.variedad);
    const cliente: ClienteDTO = JSON.parse(localStorage.getItem('Usuario'));

    //this.emitirCajaSelecionada(this.appService.CajaArmada)
    // TODO: End point que nos devuelvael json filtrado por proveedores y paginado
    // this.appService.getProductosWebShop('120' , '20230610'  , 'ROSES' , 1 , 24).subscribe(data => {
    //   const cajas: Caja[] = JSON.parse(data.json);
    //   if (this.appService.codigosProveedorRespaldo.length == 0) {
    //     this.appService.codigosProveedorFinales = this.DatosProductosIu;
    //     this.appService.codigosProveedorRespaldo = this.appService.codigosProveedorFinales;
    //   } else {
    //     this.appService.codigosProveedorFinales = [];
    //     for (let itemCodigo of this.appService.codigosProveedorRespaldo) {
    //       if (this.DatosProductosIu.filter(x => x == itemCodigo).length > 0) {
    //         this.appService.codigosProveedorFinales.push(itemCodigo);
    //       }
    //     }
    //     this.appService.codigosProveedorRespaldo = [];
    //     this.appService.codigosProveedorRespaldo = this.appService.codigosProveedorFinales;
    //   }
    //   this.buscarProveedores(this.appService.codigosProveedorFinales);
    //   for (let codigos of this.appService.codigosProveedorFinales) {
    //     this.DatosProductos = this.DatosProductos.concat(cajas.filter(x => x.variedades[0].codigosProveedor.find(y => y === codigos)))
    //     if (this.paginaRuta === '/promo') {
    //       this.DatosProductos = this.DatosProductos.filter(x => x.promocion == "S");
    //     }
    //     if (this.paginaRuta === '/stading') {
    //       this.DatosProductos = this.DatosProductos.filter(x => x.variedades.find(y => y.precioSO > 0));
    //     }
    //   }
    //   this.productos = [];
    //   var uniques = Array.from(new Set(this.DatosProductos));
    //   this.productos = uniques;
    //   var stading = null;
    //   if (this.paginaRuta === '/stading') { stading = true } else { stading = false };
    //   this.DatosProductosOu.emit(this.appService._productosfiltradosPorProveedor(
    //     this.productos,
    //     cliente,
    //     this.paginaRuta,
    //     this.sePuedeMezclar,
    //     this.producto.variedades[0].producto,
    //     this.productos,
    //     stading
    //   ));
    // });
    this.DatosProductosOu.emit(null)
    this.DatosProductos = [];
  }

  public buscarProveedores(listaProveedores: number[]) {
    this.appService.getProveedoresConCodigo().subscribe(data => {
      this.appService.proveedoresFinalesMostrar = []
      this.resultadoProveedores = []
      for (let codigo of listaProveedores) {
        this.resultadoProveedores = data.filter(x => x.codigoProveedor === codigo)
        if (this.resultadoProveedores.length > 0) {
          this.appService.proveedoresFinalesMostrar.push(this.resultadoProveedores[0].nombresProveedor)
        }
        this.resultadoProveedores = []
      }
    });
  }

  public emitirCajaSelecionada(cajaSeleccionada: string) {
    this.cajaSeleccionadaActualizada.emit(cajaSeleccionada);
  }

}
