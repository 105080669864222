import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-note-boxes',
  templateUrl: './note-boxes.component.html',
  styleUrls: ['./note-boxes.component.scss']
})
export class NoteBoxesComponent implements OnInit {

  constructor(public appService: AppService, public dialog: MatDialog, public router: Router,
    public dialogRef: MatDialogRef<NoteBoxesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.condicion == 'EDIT') {
      this.appService.getImagenBarraProgreso().subscribe(data => {
        this.appService._validarCajaSeleccionada(this.appService.cajaSeleccionada, data);
      });
    }
    if (this.data.condicion == 'ADD') {
      this.appService.getImagenBarraProgreso().subscribe(data => {
        this.appService._validarCajaSeleccionada(this.appService.cajaSeleccionada, data);
      });
    }
    this.appService.CajaArmada.totalProcentajeLleno = Math.round(this.appService.CajaArmada.totalProcentajeLleno);
  }

  _accept() {
    this.dialogRef.close(true)
  }

  _enlarge() {
    this.dialogRef.close(true)
  }

  _cancel(){
    //this.dialogRef.close(false)
    this.dialogRef.close("Agregar");
  }

  

}
