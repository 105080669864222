import { Component, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-product-controls',
  templateUrl: './product-controls.component.html',
  styleUrls: ['./product-controls.component.scss']
})
export class ProductControlsComponent {

  @Input() quantity: number = 0;
  @Output() quantityChange = new EventEmitter<number>();
  
  increaseQuantity() {
    this.quantity++;
    this.quantityChange.emit(this.quantity);
  }
  
  decreaseQuantity() {
    if (this.quantity > 0) {
      this.quantity--;
      this.quantityChange.emit(this.quantity);
    }
  }

  editQuantity(newQuantity: number) {
    if (newQuantity >= 0) {
      this.quantity = newQuantity;
      this.quantityChange.emit(this.quantity);
    }
  }

}
