import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public productoRespaldo: any;

  imageUpdated: EventEmitter<string> = new EventEmitter<string>();
  dataProductUpdate: EventEmitter<string> = new EventEmitter<string>();

  respaldarDatosProductoAgregar(datos: any) {
    this.productoRespaldo = datos;
  }
}